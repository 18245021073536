import KeyValue from '@support/models/keyValue'


export enum WorkflowEventType {
  TOGGLE = 0,
  CLICK,
}

export interface WorkflowEvent {
  event: WorkflowEventType,
  id?: number | string | undefined,
  key?: string,
  path?: KeyValue
}

export interface WorkflowItemObj {
  expanded?: boolean,
  id: string | undefined,
  path: KeyValue,
  data: KeyValue,
  items?: WorkflowItemObj[]
}

export interface WorkflowCol {
  key: string,
  label: string,
  expandable?: boolean,
  clickable?: boolean,
  showTotal?: boolean,
  className?: string
}

export interface WorkflowDefinition {
  className?: string,
  columns: WorkflowCol[],
  onListViewEvent?: (event: any) => void
}