import { useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';
import DatePicker from '@src/support/components/datePicker';

type Props = {
  readOnly?: boolean,
}

export default function General({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler } = useFormVisibility();

  const {
    record,
  } = useAppSelector(state => state.lisRecord)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  const field_10 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 10,
    description: 'Verwijzing',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_11 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 11,
    description: 'Ambulance',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_12 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 12,
    description: 'Datum oplopen letsel',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  
  
  const field_13 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 13,
    description: 'Dagdeel oplopen letsel',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_14 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 14,
    description: 'Datum binnenkomst SEH',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));    

  const field_15 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 15,
    description: 'Tijdstip binnenkomst SEH',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  })); 

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Algemeen</legend>
      <FormRow fieldId="10" isActive={visibility[10]} fieldRules={field_10.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_10.current.description}</span>
        <Controller
          control={methods.control}
          name="10"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error} onChange={onChange} options={field_10.current.options} valueRules={field_10.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="11" isActive={visibility[11]} fieldRules={field_11.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_11.current.description}</span>
        <Controller
          control={methods.control}
          name="11"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error} onChange={onChange} options={field_11.current.options} valueRules={field_11.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="12" isActive={visibility[12]} fieldRules={field_12.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_12.current.description}</span>
        <Controller
          control={methods.control}
          name="12"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <DatePicker name={name} selected={value ? new Date(value) : null} error={error} onChange={onChange} />
          )}
        />;        
      </FormRow>

      <FormRow fieldId="13" isActive={visibility[13]} fieldRules={field_13.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_13.current.description}</span>
        <Controller
          control={methods.control}
          name="13"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_13.current.options} valueRules={field_13.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="14" isActive={true}>
        <span>{field_14.current.description}</span>
        <span>{record?.[14]}</span>
      </FormRow>

      <FormRow fieldId="15" isActive={true}>
        <span>{field_15.current.description}</span>
        <span>{record?.[15]}</span>
      </FormRow>
    </FormFieldset>
  )
}