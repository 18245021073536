// app
import SubLayout1Col from '@layouts/subLayouts/SubLayout1Col';
import SectionDefault from '@layouts/sections/SectionDefault';

// support
import Breadcrumbs from '@support/components/breadcrumbs';

// RecordRav
import MoveRecord from '@src/features/moveRecord';

export default function MoveDetail() {
  return (
    <>
      <SectionDefault>
        <SubLayout1Col>
          <Breadcrumbs />
        </SubLayout1Col>
        <SubLayout1Col>
          <MoveRecord />
        </SubLayout1Col>
      </SectionDefault>
    </>
  );
}
