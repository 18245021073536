
import { useCallback, useEffect, useState, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { useParams, useNavigate } from 'react-router-dom';

import SubLayout3Cols from '@layouts/subLayouts/SubLayout3Cols';
import SectionDefault from '@layouts/sections/SectionDefault';
import ActionBar from '@support/components/actionBar';

import Form from '@src/support/components/form';
import Button from '@src/support/components/button';

// features
import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';
import Popup from '@support/components/popup';
import { PopupObject, PopupType } from '@support/components/popup/models';

import ControlCenterText from '@src/features/moveRecord/components/ControlCenterText';
import InternalMessage from '@src/features/moveRecord/components/InternalMessage';
import Record from '@src/features/moveRecord/components/Record';
import Person from '@src/features/moveRecord/components/Person';
import Location from '@src/features/moveRecord/components/Location';
import AmbulanceText from '@src/features/moveRecord/components/AmbulanceText';
import AccidentCharacteristics from '@src/features/moveRecord/components/AccidentCharacteristics';
import RoadwayFactors from '@src/features/moveRecord/components/RoadwayFactors';
import EnvironmentalFactors from '@src/features/moveRecord/components/EnvironmentalFactors';
import Attributions from '@src/features/moveRecord/components/Attributions';
import PersonCharacteristics from '@src/features/moveRecord/components/PersonCharacteristics';
import ProtectiveEquipment from '@src/features/moveRecord/components/ProtectiveEquipment';
import AccidentFacts from './components/AccidentFacts';
import Diagnose from './components/Diagnose';
import KeyValue from '@src/support/models/keyValue';

// support
import Throbber from '@support/components/throbber';
import TitleHeader, { Props as TitleHeaderProps } from '@support/components/titleHeader';
import { useBlocker } from '@support/hooks/useBlocker';
import { useBeforeUnload } from '@support/hooks/useBeforeUnload';

// record store
import {
  setRecord,
  fetchMoveRecordAsync,
  saveRecordAsync,
  discussRecordAsync,
  approveRecordAsync,
  unlockCurrentRecordAsync,
  lockCurrentRecordAsync,
  discardDuplicateRecordAsync,
  stageDuplicateRecordAsync,
  STATUS_DUPLICATE, STATUS_DECLINED, STATUS_ACCEPTED
} from '@features/moveRecord/store/slice';

import {
  removePrevNextRecordId
} from '@features/moveRegistration/store/slice';

import './index.scss';
import { SubmitEventType } from '@src/support/components/form/models';

type Params = {
  id: string
}

export default function MoveRecord() {

  const [popupObj, setPopupObj] = useState<PopupObject | undefined>();
  const formIsDirty = useRef<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const localRecord = useRef<KeyValue>({});

  const routeParams = useParams() as Params;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    record,
    status,
  } = useAppSelector(state => state.moveRecord)

  const {
    recordIds,
  } = useAppSelector(state => state.moveRegistration)

  const header: TitleHeaderProps = {
    title: routeParams.id,
    navigationButton: {
      title: 'Geschiedenis',
      url: `/move/registration/${routeParams.id}/history`
    }
  }

  /**
   * unlock the current record and continue navigation
   * @context: NavigationContext
   */
  const unlockAndContinue = useCallback((context: any) => {
    formIsDirty.current = false;
    if (record?.lockedByOther === false) {
      dispatch(unlockCurrentRecordAsync()).unwrap().then(() => {
        if (context.retry) context.retry();
      }).catch(e => {
        dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij unlocken van het record.', autoDismissMilliseconds: 3000 }));
      })
    } else {
      if (context.retry) context.retry();
    }
  }, [dispatch, record?.lockedByOther]);

  /**
    * Notify user when navigating away when not using the React routing (i.e. external link, close tab)
    * Unfortunately, there's no way to control the message or unlock/save the message before 
    * navigating away.
    */
  useBeforeUnload();

  /**
   * Prevent from navigating to another record, when there are changes
   * @context the blocked navigation context
   * 
   * When the user navigates away and the form is dirty, save and unlock the record first.
   * Else, just unlock the record before navigating away.
   * 
   * Note, this only works when navigating within the application. All other cases are catched
   * by useBeforeUnload.
   */
  useBlocker((context: any) => {
    if (formIsDirty.current) {
      const popup: PopupObject = {
        type: PopupType.WARNING,
        title: 'Wijzigingen opslaan?',
        text: 'Wil je de wijzigingen aan deze record opslaan?',
        buttonConfirm: 'Opslaan',
        onConfirm: () => { // save record and unlock before leaving page
          dispatch(saveRecordAsync(localRecord.current)).unwrap().then(() => {
            dispatch(addNotification({ type: NotificationType.INFO, text: 'Record is opgeslagen', autoDismissMilliseconds: 3000 }));
            unlockAndContinue(context);
          }).catch((e) => {
            dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij opslaan van het record.', autoDismissMilliseconds: 3000 }));
          });
        },
        buttonCancel: 'Niet opslaan',
        onCancel: () => { // don't save record, unlock before leaving page
          unlockAndContinue(context);
        },
      }
      setPopupObj(popup);
    } else { // form not dirty, so don't save, unlock before leaving page
      unlockAndContinue(context);
    }
  }, true);

  /**
   * Load new record when param id changes
   */
  useEffect(() => {
    if (routeParams.id) {
      dispatch(fetchMoveRecordAsync(routeParams.id)).unwrap().then((rec: any) => {

        localRecord.current = rec;
        formIsDirty.current = false;

        setDisableSaveButton(true);

        if (rec?.lockedByOther === false) {
          dispatch(lockCurrentRecordAsync()).unwrap().catch((e) => {
            dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij locken van het record.', autoDismissMilliseconds: 3000 }));
          });
        }
        window.scrollTo({ top: 0 });
      }).catch((e) => {
        dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij ophalen van het record.', autoDismissMilliseconds: 3000 }));
      })
    }
  }, [dispatch, routeParams.id])

  /**
   * Handle dirty change
   */
  const handleDirty = useCallback(() => {
    formIsDirty.current = true;
    setDisableSaveButton(false);
  }, []);

  /**
   * Handle data change
   */
  const handleData = useCallback((data: object) => {
    localRecord.current = data;
  }, []);

  /**
   * Handle previous record
   */
  const navigateToPrevRecord = useCallback(() => {
    if (recordIds) {
      const recordIndex: number = recordIds.indexOf(record?.id);
      if (recordIndex !== -1 && recordIndex > 0) {
        const previousRecordId: string = recordIds[recordIndex - 1];

        navigate(`/move/registration/${previousRecordId}`);
      } else {
        navigate('/move/registration/');
      }
    }
  }, [navigate, record?.id, recordIds]);

  /**
   * Handle next record
   */
  const navigateToNextRecord = useCallback(() => {
    if (recordIds) {
      const recordIndex: number = recordIds.indexOf(record?.id);
      if (recordIndex !== -1 && recordIndex < recordIds.length - 1) {
        const nextRecordId: string = recordIds[recordIndex + 1];
        navigate(`/move/registration/${nextRecordId}`);
      } else {
        navigate('/move/registration/');
      }
    }
  }, [navigate, record?.id, recordIds]);

  /**
   * Save record
   * Just save the current state, validation is not triggered
   */
  const handleSaveRecord = useCallback(() => {
    dispatch(saveRecordAsync(localRecord.current)).unwrap().then((e) => {
      formIsDirty.current = false;
      setDisableSaveButton(true);
      dispatch(setRecord(localRecord.current))
      dispatch(addNotification({ type: NotificationType.INFO, text: 'De aanpassingen zijn opgeslagen', autoDismissMilliseconds: 3000 }));
    }).catch((e) => {
      dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij het opslaan van het record.', autoDismissMilliseconds: 3000 }));
    })
  }, [dispatch]);

  /**
   * Discuss record
   */
  const handleDiscussRecord = useCallback(() => {
    dispatch(discussRecordAsync(localRecord.current)).unwrap().then((e) => {
      dispatch(addNotification({ type: NotificationType.INFO, text: 'De aanpassingen zijn opgeslagen', autoDismissMilliseconds: 3000 }));
      formIsDirty.current = false;
      setDisableSaveButton(true);
      navigateToNextRecord();
    }).catch((e) => {
      dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij het aanpassen van de status.', autoDismissMilliseconds: 3000 }));
    })
  }, [dispatch, navigateToNextRecord]);

  /**
 * Discard duplicate record
 */
  const handleDiscardDuplicateRecord = useCallback(() => {
    dispatch(discardDuplicateRecordAsync(localRecord.current)).unwrap().then((e) => {
      dispatch(addNotification({
        type: NotificationType.INFO,
        text: 'De aanpassingen zijn opgeslagen',
        autoDismissMilliseconds: 3000
      }));
      formIsDirty.current = false;
      setDisableSaveButton(true);
      dispatch(removePrevNextRecordId(localRecord.current.id));
      navigateToNextRecord();
    }).catch((e) => {
      switch (e.status) {
        default:
          dispatch(addNotification({
            type: NotificationType.WARNING,
            text: 'Fout bij het goedkeuren van het record.',
            autoDismissMilliseconds: 3000
          }));
          break;
        case 403:
          dispatch(addNotification({
            type: NotificationType.WARNING,
            text: 'Je bent niet gemachtigd om duplicaten te bewerken.',
            autoDismissMilliseconds: 3000
          }));
          break;
      }
    })
  }, [dispatch, navigateToNextRecord]);

  /**
   * Stage duplicate record
   */
  const handleStageDuplicateRecord = useCallback(() => {
    dispatch(stageDuplicateRecordAsync(localRecord.current)).unwrap().then((e) => {
      dispatch(addNotification({
        type: NotificationType.INFO,
        text: 'De aanpassingen zijn opgeslagen',
        autoDismissMilliseconds: 3000
      }));
      formIsDirty.current = false;
      setDisableSaveButton(true);
      dispatch(removePrevNextRecordId(localRecord.current.id));
      navigateToNextRecord();
    }).catch((e) => {
      switch (e.status) {
        default:
          dispatch(addNotification({
            type: NotificationType.WARNING,
            text: 'Fout bij het goedkeuren van het record.',
            autoDismissMilliseconds: 3000
          }));
          break;
        case 422:
          dispatch(addNotification({
            type: NotificationType.WARNING,
            text: 'Een duplicaat met dit nummer is al toegevoegd.',
            autoDismissMilliseconds: 3000
          }));
          break;
        case 403:
          dispatch(addNotification({
            type: NotificationType.WARNING,
            text: 'Je bent niet gemachtigd om duplicaten te bewerken.',
            autoDismissMilliseconds: 3000
          }));
          break;
      }
    })
  }, [dispatch, navigateToNextRecord]);

  /**
   * Approve record
   */
  const handleApproveRecord = useCallback((type: SubmitEventType, form: any) => {

    switch (type) {
      case SubmitEventType.SUCCESS: {
        dispatch(approveRecordAsync(localRecord.current)).unwrap().then((e) => {
          dispatch(addNotification({ type: NotificationType.INFO, text: 'De aanpassingen zijn opgeslagen', autoDismissMilliseconds: 3000 }));
          formIsDirty.current = false;
          navigateToNextRecord();
        }).catch((e) => {
          dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij het goedkeuren van het record.', autoDismissMilliseconds: 3000 }));
        })
        break;
      }
      case SubmitEventType.ERROR: {

        const errors = Object.values(form);
        const uniquePairsLetselError = errors.some((item: any) => item.type === 'uniquePairsLetsel');

        if (uniquePairsLetselError) {
          dispatch(addNotification({ type: NotificationType.WARNING, text: 'Dubbele letsel/lichaamsdeel waarden.', autoDismissMilliseconds: 3000 }));
        }

        dispatch(addNotification({ type: NotificationType.WARNING, text: 'Vul alle verplichte velden.', autoDismissMilliseconds: 3000 }));
        break;
      }
    }
  }, [dispatch, navigateToNextRecord]);

  return (
    <>
      <Popup popup={popupObj} />
      <Throbber visible={status === 'loading'} className="throbber--secondary" />
      {record === undefined || status === 'failed' ? (
        <>
          {status !== 'loading' &&
            <div className="listview__no-result">
              <div>Het record kon niet geladen worden</div>
            </div>
          }
        </>
      ) : (
        <div className="move-record">
          <TitleHeader title={header.title} navigationButton={header.navigationButton} />
          <div key={record.uniqueKey}>
            <Form defaultValues={record} onDirtyEvent={handleDirty} onChangeEvent={handleData} onSubmitEvent={handleApproveRecord}>
              <SectionDefault className="section--compact section--margin-t-m">
                <SubLayout3Cols className="gutter-l">
                  <InternalMessage readOnly={record.readOnly} />
                  <></>
                </SubLayout3Cols>
              </SectionDefault>
              <SectionDefault className="section--compact">
                <SubLayout3Cols className="gutter-l">
                  <Record readOnly={record.readOnly} />
                  <Person readOnly={record.readOnly} />
                  <Location readOnly={record.readOnly} />
                </SubLayout3Cols>
              </SectionDefault>
              <SectionDefault className="section--compact">
                {record.type === 'RAV' ? (
                  <SubLayout3Cols className="gutter-l">
                    <ControlCenterText readOnly={record.readOnly} />
                    <AmbulanceText readOnly={record.readOnly} />
                  </SubLayout3Cols>
                ) : (
                  <SubLayout3Cols className="gutter-l">
                    <AccidentFacts readOnly={record.readOnly} />
                    <Diagnose readOnly={record.readOnly} />
                  </SubLayout3Cols>
                )}
              </SectionDefault>
              <SectionDefault className="section--compact">
                <SubLayout3Cols className="gutter-l">
                  <>
                    <AccidentCharacteristics readOnly={record.readOnly} />
                    <PersonCharacteristics readOnly={record.readOnly} />
                    <ProtectiveEquipment readOnly={record.readOnly} />
                  </>
                  <>
                    <RoadwayFactors readOnly={record.readOnly} />
                    <EnvironmentalFactors readOnly={record.readOnly} />
                  </>
                  <Attributions readOnly={record.readOnly} />
                </SubLayout3Cols>
              </SectionDefault>

              <ActionBar active hasPaging buttonPrevCallback={navigateToPrevRecord} buttonNextCallback={navigateToNextRecord}>
                {record.lockedByOther ? (
                  <div className="action-bar__locked">{record.lockedByUsername}</div>
                ) : (
                  <>
                    {record?.status === STATUS_DUPLICATE ? (
                      <>
                        <Button className="button--negative" onClick={(e) => { handleDiscardDuplicateRecord(); e.preventDefault() }}>Verwijderen</Button>
                        <Button className="button--positive" onClick={(e) => { handleStageDuplicateRecord(); e.preventDefault() }}>Toevoegen</Button>
                      </>
                    ) : (
                      <>
                        <Button className="button--negative" disabled={record.status === STATUS_DECLINED} onClick={(e) => { handleDiscussRecord(); e.preventDefault() }}>Bespreken</Button>
                        <Button className="button--secondary" disabled={disableSaveButton} onClick={(e) => { handleSaveRecord(); e.preventDefault() }}>Wijzigingen opslaan</Button>
                        <Button className="button--positive" type="submit" disabled={record.status === STATUS_ACCEPTED}>Goedkeuren</Button>
                      </>
                    )}
                  </>
                )}
              </ActionBar>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}