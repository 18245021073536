import { useLocation, NavLink, Location } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './index.scss'

interface BreadcrumbItem {
    title: string,
    link: string
}

/**
 * Converts a location path segment to display name
 *
 * @param locationSegment - The location path segment 
 * @returns The display name for the given path segment
 *
 */
const locationSegmentToName = (locationSegment: string): string => {
    switch (locationSegment) {
        default:
            return locationSegment;
        case 'lis':
            return 'LIS';
        case 'move':
            return 'MOVE';
        case 'registration':
            return 'Registraties';
        case 'hospitals':
            return 'Ziekenhuizen';
        case 'history':
            return 'Geschiedenis';
        case 'workflow':
            return 'Workflow';
        case 'organizations':
            return 'Organisaties';
    }
}

/**
 * Converts a react-router-dom Location object to an array of BreadcrumbItem elements
 *
 * @param location - A react-router-dom Location object
 * @returns An array of BreadcrumbItem elements
 *
 */
const locationToBreadcrumbItems = (location: Location): BreadcrumbItem[] => {
    const pathSplit = location.pathname.substring(1).split('/');
    const tmp = [] as BreadcrumbItem[];
    let path = '';
    pathSplit.forEach((item) => {
        path += '/' + item;
        tmp.push({ title: locationSegmentToName(item), link: path })
    })
    tmp.pop();
    return tmp;
};

export default function Breadcrumbs() {

    const location = useLocation();
    const [items, setItems] = useState<BreadcrumbItem[]>(locationToBreadcrumbItems(location));

    useEffect(() => {
        setItems(locationToBreadcrumbItems(location));
    }, [location])

    return (
        <>
            {items.length > 0 && (
                <ul className="breadcrumbs">
                    {items.map((item: BreadcrumbItem, index: number) => (
                        <li key={index}><NavLink to={item.link}>{item.title}</NavLink></li>
                    ))}
                </ul>
            )}
        </>
    );
}
