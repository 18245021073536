import { useCallback } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';

// features
import Filter from '@features/lisWorkflowReasonVisit/components/filter';

import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';

// support
import Throbber from '@support/components/throbber';
import WorkflowView from '@support/components/workflowView';
import { WorkflowEvent, WorkflowEventType } from '@support/components/workflowView/models';

// store
import {
    fetchLisWorkflowReasonVisitAsync,
    setPath
} from '@features/lisWorkflowReasonVisit/store/slice'

import workflowDefinition from './workflowDefinition';

export default function LisWorkflowReasonVisit() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        items,
        status,
        filter,
    } = useAppSelector(state => state.lisWorkflowReasonVisit)

    /**
     * Filter has changed, get new items
     */
    useEffect(() => {
        dispatch(fetchLisWorkflowReasonVisitAsync()).unwrap().catch(() => {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Fout bij ophalen van de data...',
                autoDismissMilliseconds: 3000
            }));
        });
    }, [dispatch, filter])

    /**
     * Handle list view events
     */
    const onWorkflowEvent = useCallback((e: WorkflowEvent) => {
        switch (e.event) {
            case WorkflowEventType.CLICK:
                if (e.id) navigate(`/lis/registration/${e.id}`);
                break;
            case WorkflowEventType.TOGGLE:
                if (e.key && e.path) {
                    dispatch(setPath({ key: e.key, path: e.path }));
                }
                break;
        }
    }, [navigate, dispatch]);

    return (
        <>
            <Filter />
            <Throbber visible={status === 'loading'} />
            {items.length === 0 ? (
                <>
                    {status !== 'loading' &&
                        <div className="listview__no-result">
                            <div>Geen resultaten</div>
                        </div>
                    }
                </>
            ) : (
                <WorkflowView onWorkflowEvent={onWorkflowEvent} items={items} definition={workflowDefinition} />
            )}
        </>
    );
}

