import '@scss/theme/index.scss';
import './App.scss';
import MasterLayout from '@app/layouts/masterLayout';
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Header from './layouts/header';

// features
import Cache from '@features/cache';

// pages
import LisDataLineagePage from './pages/lis/dataLineage';
import LisHistoryPage from './pages/lis/history';
import LisRegistrationPage from './pages/lis/registration';
import LisRegistrationDetailPage from './pages/lis/registrationDetail';
import LisUploadsPage from './pages/lis/uploads';
import LisWorkflowDatePage from './pages/lis/workflowDate';
import LisWorkflowReasonVisitPage from './pages/lis/workflowReasonVisit';
import MoveDataLineagePage from './pages/move/dataLineage';
import MoveRegistrationPage from './pages/move/registration';
import MoveHistoryPage from './pages/move/history';
import MoveRegistrationDetailPage from './pages/move/registrationDetail';
import MoveRegistrationWorkflowPage from './pages/move/workflow';
import MoveUploadsPage from './pages/move/uploads';
import OrganizationsPage from './pages/organizations/organizations';
import OrganizationDetailsPage from './pages/organizations/organizationDetails';

export const history = createBrowserHistory();

function App() {
  return (
    <>
      {/* authenticated in routes */}
        <Router>
          <MasterLayout
            header={<Header authenticated />}
            main={(
              <Cache>
                <Routes>
                  <Route path="/" element={<Navigate replace to="/lis/registration" />} />
                  <Route path="/lis" element={<Navigate replace to="/lis/registration" state={{ resetPaging: true }} />} />
                  <Route path="/lis/uploads" element={<LisUploadsPage />} />
                  <Route path="/lis/registration" element={<LisRegistrationPage />} />
                  <Route path="/lis/registration/:id" element={<LisRegistrationDetailPage />} />
                  <Route path="/lis/registration/:id/history" element={<LisHistoryPage />} />
                  <Route path="/lis/registration/:id/history/data-lineage" element={<LisDataLineagePage />} />
                  <Route path="/lis/workflow" element={<LisWorkflowDatePage />} />
                  <Route path="/lis/workflow/reason-visit" element={<LisWorkflowReasonVisitPage />} />
                  <Route path="/move" element={<Navigate replace to="/move/registration" state={{ resetPaging: true }} />} />
                  <Route path="/move/uploads" element={<MoveUploadsPage />} />
                  <Route path="/move/registration" element={<MoveRegistrationPage />} />
                  <Route path="/move/registration/:id" element={<MoveRegistrationDetailPage />} />
                  <Route path="/move/registration/:id/history" element={<MoveHistoryPage />} />
                  <Route path="/move/registration/:id/history/data-lineage" element={<MoveDataLineagePage />} />
                  <Route path="/move/workflow" element={<MoveRegistrationWorkflowPage />} />
                  <Route path="/organizations" element={<OrganizationsPage />} />
                  <Route path="/organizations/:id" element={<OrganizationDetailsPage />} />
                </Routes>
              </Cache>
            )}
          />
        </Router>
    </>
  );
}

export default App;
