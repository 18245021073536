import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import MoveWorkflowAPI from '@features/moveWorkflow/api/api';
import { MoveWorkflowFilter } from '@features/moveWorkflow/models/models'

// support
import { WorkflowItemObj } from '@support/components/workflowView/models';
import { updateFilter } from '@support/components/workflowView/helper';
import KeyValue from '@support/models/keyValue'

export interface MoveWorklowState {
  status: 'idle' | 'loading' | 'failed',
  items: WorkflowItemObj[],
  path: KeyValue,
  filter: MoveWorkflowFilter
}

const initialState: MoveWorklowState = {
  status: 'idle',
  items: [],
  path: {},
  filter: {
    status: '',
    year: '',
    month: '',
    quarter: '',
    date: '',
    startDate: '',
    endDate: '',
    query: '',
    region: 'Total',
  }
};

export const fetchWorkflowAsync = createAsyncThunk<WorkflowItemObj[], void, { state: RootState }>(
  'moveWorkflow/fetchWorkflowAsync',
  async (_, thunkAPI) => {

    const filter = thunkAPI.getState().moveWorkflow.filter;
    const params = new URLSearchParams();
    if (filter.status) params.append('status', filter.status);
    if (filter.year) params.append('year', filter.year);
    if (filter.month) params.append('month', filter.month);
    if (filter.quarter) params.append('quarter', filter.quarter);
    if (filter.date) params.append('date', filter.date);
    if (filter.startDate) params.append('startDate', filter.startDate);
    if (filter.endDate) params.append('endDate', filter.endDate);
    if (filter.query) params.append('query', filter.query);
    if (filter.region) params.append('region', filter.region);

    try {
      return await MoveWorkflowAPI.workflow(params);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const moveWorkflowSlice = createSlice({
  name: 'moveWorkflow',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPath: (state, action: PayloadAction<{ key: string, path: KeyValue }>) => {
      const levels: string[] = ['status', 'year', 'quarter', 'month', 'date'];
      updateFilter(action.payload.key, action.payload.path, state.filter, levels)
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.filter.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.filter.endDate = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      if (state.filter.query !== action.payload) {
        state.filter.query = action.payload;
      }
    },
    setRegion: (state, action: PayloadAction<string>) => {
      state.filter.region = action.payload;
    },
    setItems: (state, action: PayloadAction<WorkflowItemObj[]>) => {
      state.items = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // --------------------------------------------------------------------------
      // workflow
      .addCase(fetchWorkflowAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkflowAsync.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchWorkflowAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setPath, setStartDate, setEndDate, setQuery, setRegion, setItems } = moveWorkflowSlice.actions;

export default moveWorkflowSlice.reducer;
