import { ChangeEventHandler } from 'react';

// features
import { OrganizationDetailsResponse } from '@features/organizationDetails/models/models';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import InputCheckbox from '@src/support/components/inputCheckbox';

import './index.scss';

export interface Props {
    organization: OrganizationDetailsResponse
    onChangeAutoProcessing: ChangeEventHandler<HTMLInputElement>,
}

export default function GeneralDetails({ organization, onChangeAutoProcessing }: Props) {
    return (
        <FormFieldset className={'block mb-4 organization-details__general'}>
            <legend className="h2">
                Algemeen
            </legend>
            <FormRow fieldId="data" isActive={true}>
                <span>Data</span>
                <span>{organization.data}</span>
            </FormRow>
            <FormRow fieldId="region" isActive={true}>
                <span>Regio</span>
                <span>{organization.region}</span>
            </FormRow>
            <FormRow fieldId="code" isActive={true}>
                <span>Code</span>
                <span>{organization.code}</span>
            </FormRow>
            <FormRow fieldId="organization" isActive={true}>
                <span>Organisatie</span>
                <span>{organization.description}</span>
            </FormRow>
            <FormRow fieldId="startParticipation" isActive={true}>
                <span>Start deelname</span>
                <span>{organization.startParticipation}</span>
            </FormRow>
            <FormRow fieldId="endParticipation" isActive={true}>
                <span>Einde deelname</span>
                <span>{organization.endParticipation}</span>
            </FormRow>
            <FormRow fieldId="autoProcessing" isActive={true}>
                <span>Automatisch verwerking</span>
                <InputCheckbox
                    name="autoProcessing"
                    config={{
                        value: organization.autoProcessing,
                        onChange: onChangeAutoProcessing
                    }}
                />
            </FormRow>
        </FormFieldset>
    );
}
