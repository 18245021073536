import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { useParams } from 'react-router-dom';

// features
import TitleHeader, { Props as TitleHeaderProps } from '@support/components/titleHeader';
import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';

// support
import Pagination from '@support/components/pagination';
import ListView from '@support/components/listView';
import ListViewItemDataHelper from '@support/components/listView/listViewDataHelper';
import { ListViewItemData, ListViewEventType, ListViewEvent, ListViewSort } from '@support/components/listView/models';
import Throbber from '@support/components/throbber';

// store
import {
    fetchLisRecordHistoryListAsync,
    setPage,
    setSortKey,
} from '@src/features/lisRecordHistory/store/slice';

import listDefinition from './listDefinition';

type Params = {
    id: string
}

export default function LisRecordHistory() {
    const { id } = useParams() as Params;

    const header: TitleHeaderProps = {
        title: 'Geschiedenis',
        navigationButton: {
            title: 'Data lineage',
            url: `/lis/registration/${id}/history/data-lineage`
        }
    }

    const [listViewData, setListViewData] = useState<ListViewItemData[]>([]);
    const dispatch = useAppDispatch();

    const {
        listData,
        status,
        currentPage,
        filter,
        totalPages
    } = useAppSelector(state => state.lisRecordHistory);

    /**
     * Load the history list items for a specific record.
     */
    const loadItems = useCallback(() => {
        dispatch(fetchLisRecordHistoryListAsync({id})).unwrap().catch(() => {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Fout bij ophalen van de lijst.',
                autoDismissMilliseconds: 3000
            }));
        })
    }, [dispatch, id]);

    /**
     * Filter has changed, get new items
     */
    useEffect(() => {
        loadItems();
    }, [loadItems, filter])

    /**
     * Transform raw data for use in listview as defined in songListDefinition
     */
    useEffect(() => {
        if (listData?.items) {
            setListViewData(ListViewItemDataHelper.transformData(listData.items, listDefinition));
            window.scrollTo({
                top: 0
            });
        }
    }, [listData]);

    /**
    * Handle paging changes
    */
    const handlePageChange = useCallback((page: number) => {
        dispatch(setPage(page));
    }, [dispatch]);

    /**
     * Handle list view events
     */
    const onListViewEvent = useCallback((e: ListViewEvent) => {
        switch (e.event) {
            case ListViewEventType.SORT_COLUMN:
                if (e.payload !== undefined) {
                    dispatch(setSortKey(e.payload as ListViewSort));
                }
                break;
        }
    }, [dispatch]);

    return (
        <>
            <TitleHeader title={header.title} navigationButton={header.navigationButton} />
            <Throbber visible={status === 'loading'} />
            {
                listViewData.length === 0 ? (
                    <>
                        {status !== 'loading' &&
                            <div className="listview__no-result">
                                <div>Geen resultaten</div>
                            </div>
                        }
                    </>
                ) : (
                    <>
                        <ListView sortKey={filter.sort} definition={listDefinition} onListViewEvent={onListViewEvent} data={listViewData} />
                        <Pagination textPrevious="Vorige" textNext="Volgende" currentPage={currentPage} numPages={totalPages} onPageChange={handlePageChange} />
                    </>
                )
            }
        </>
    )
}

