import { useRef, useEffect } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect, getValueLabel } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

type Props = {
  readOnly?: boolean,
  reasonRejectError?: boolean
}

export default function Record({ readOnly = false, reasonRejectError = false }: Props) {
  const methods = useFormContext();
  const setError = methods.setError;
  const clearErrors = methods.clearErrors;

  const { visibility, updateHandler } = useFormVisibility();

  const {
    record,
  } = useAppSelector(state => state.lisRecord)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  // trigger error when 'reject reason' is empty and user is rejecting 
  // the record
  useEffect(() => {
    if(reasonRejectError) {
      setError('2', { type: 'required', 'message':''});
    } else {
      clearErrors('2');
    }
  }, [setError, clearErrors, reasonRejectError])

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  const field_1 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 1,
    description: 'Status',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_2 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 2,
    description: 'Reden afkeur',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_3 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 3,
    description: 'Ziekenhuis ID',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  
  
  const field_4 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 4,
    description: 'Volgnnummer ZH',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));    

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Record</legend>
      <FormRow fieldId="1" isActive={true}>
        <span>{field_1.current.description}</span>
        <span>{getValueLabel(record?.[1], field_1.current)}</span>
      </FormRow>
      
      <FormRow fieldId="2" isActive={visibility[2]} fieldRules={field_2.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_2.current.description}</span>
        <Controller
          control={methods.control}
          name="2"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error} onChange={onChange} options={field_2.current.options} valueRules={field_2.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="3" isActive={true}>
        <span>{field_3.current.description}</span>
        <span>{record?.[3]}</span>
      </FormRow>

      <FormRow fieldId="4" isActive={true}>
        <span>{field_4.current.description}</span>
        <span>{record?.[4]}</span>
      </FormRow>
    </FormFieldset>
  )
}