import { useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

type Props = {
  readOnly?: boolean,
}

export default function MoveAccident({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  // const [combinedFieldRulesField39And60, setCombinedFieldRulesField39And60] = useState<FormFieldRule[]>([]);

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  const field_36 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 36,
    description: 'Type overig vervoersongeval',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_39 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 39,
    description: 'Vervoerswijze sltf',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  // Extra 'shadow' field for field_39, so we can evaluate the 'OR' dependency.
  // const field_60 = useRef<FormFieldSelect>(getFormSelect({
  //   fieldId: 60,
  //   description: 'Vervoerswijze sltf',
  //   formFields: formFields.current,
  //   fieldRules: formFieldRules.current,
  //   values: formValues.current,
  //   valueRules: formValueRules.current
  // }));
  // console.log('field_60','Vervoerswijze sltf',field_60)

  const field_38 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 38,
    description: 'Verkeersfunctie sltf',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  

  const field_37 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 37,
    description: 'Aard verkeersongeval',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  

  const field_40 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 40,
    description: 'Vervoerswijze tgp',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));    

  const field_41 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 41,
    description: 'Type obstakel botsing',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  

  const field_42 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 42,
    description: 'Eenzijdig verkeersscenario',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));    

  // useEffect(() => {
  //   const field39Rules = field_39.current.fieldRules ?? [];
  //   const field60Rules = field_60.current.fieldRules ?? [];
  //   const combinedFieldRules = field39Rules.concat(field60Rules);

  //   setCombinedFieldRulesField39And60(combinedFieldRules)
  // }, [field_39, field_60]);

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Verkeersongeval</legend>
      <FormRow fieldId="36" isActive={visibility[36]} fieldRules={field_36.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_36.current.description}</span>
        <Controller
          control={methods.control}
          name="36"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_36.current.options} valueRules={field_36.current.valueRules} />
          )}
        />;
      </FormRow>

      {/* <FormRow fieldId="39" isActive={visibility[39] || visibility[60]} fieldRules={combinedFieldRulesField39And60} onFormRowEvent={updateHandler}> */}
      <FormRow fieldId="39" isActive={visibility[39]} fieldRules={field_39.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_39.current.description}</span>
        <Controller
          control={methods.control}
          name="39"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_39.current.options} valueRules={field_39.current.valueRules} />
          )}
        />;
      </FormRow>    

      <FormRow fieldId="38" isActive={visibility[38]} fieldRules={field_38.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_38.current.description}</span>
        <Controller
          control={methods.control}
          name="38"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_38.current.options} valueRules={field_38.current.valueRules} />
          )}
        />;
      </FormRow>        

      <FormRow fieldId="37" isActive={visibility[37]} fieldRules={field_37.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_37.current.description}</span>
        <Controller
          control={methods.control}
          name="37"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_37.current.options} valueRules={field_37.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="40" isActive={visibility[40]} fieldRules={field_40.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_40.current.description}</span>
        <Controller
          control={methods.control}
          name="40"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_40.current.options} valueRules={field_40.current.valueRules} />
          )}
        />;
      </FormRow>    

      <FormRow fieldId="41" isActive={visibility[41]} fieldRules={field_41.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_41.current.description}</span>
        <Controller
          control={methods.control}
          name="41"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_41.current.options} valueRules={field_41.current.valueRules} />
          )}
        />;
      </FormRow>     

      <FormRow fieldId="42" isActive={visibility[42]} fieldRules={field_42.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_42.current.description}</span>
        <Controller
          control={methods.control}
          name="42"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_42.current.options} valueRules={field_42.current.valueRules} />
          )}
        />;
      </FormRow>                 
    </FormFieldset>
  )
}