import FormRow from '@src/support/components/form/FormRow';
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import InputField from '@src/support/components/inputField';
import FormFieldset from '@src/support/components/form/FormFieldset';
import { getFormSelect, getValueLabel } from '@src/support/helpers/formHelper';
import { useAppSelector } from '@src/app/store/hooks';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function Record({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    record,
  } = useAppSelector(state => state.moveRecord)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_72 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 72, description: 'Leeftijd', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_63 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 63, description: 'Leeftijd aangepast', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_73 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 73, description: 'Geslacht', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_62 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 62, description: 'Geslacht aangepast', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Persoon</legend>
      <FormRow fieldId="72" isActive={visibility[72]} fieldRules={field_72.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_72.current.description}</span>
        <span>{record?.[72]}</span>
      </FormRow>
      <FormRow fieldId="63" isActive={visibility[63]} fieldRules={field_63.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_63.current.description}</span>
        <InputField name="63" error={methods.formState.errors[63] as FieldError} />
      </FormRow>
      <FormRow fieldId="73" isActive={visibility[73]} fieldRules={field_73.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_73.current.description}</span>
        <span>{getValueLabel(record?.[73], field_73.current)}</span>
      </FormRow>
      <FormRow fieldId="62" isActive={visibility[62]} fieldRules={field_62.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_62.current.description}</span>
        <Controller
          control={methods.control}
          name="62"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic hasResetOption={true} name={name} value={value} error={error} onChange={onChange} options={field_62.current.options} valueRules={field_62.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}