
import { ListViewDefinition } from '@support/components/listView/models';

const listDefinition: ListViewDefinition = {
  hasCheckBox: false,
  hasLink: true,
  className: 'listview--lis listview--norwap listview--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    sortable: true,
    className: 'listview__col--mw-115'
  }, {
    label: 'Ziekenhuis',
    key: 'hospitalId',
    sortable: true
  }, {
    label: 'Volgnummer',
    key: 'trackingId',
    sortable: true,
    className: 'listview__col--ellipsis listview__col--mw-115'
  }, {
    label: 'Datum SEH',
    key: 'date',
    sortable: true,
    className: 'listview__col--mw-115'
  }, {
    label: 'Tijd SEH',
    key: 'time',
    sortable: true  
  }, {
    label: 'Toedracht',
    key: 'cause',
    sortable: false,
    className: 'listview__col--ellipsis listview__col--w50'
  }, {
    label: 'Diagnose',
    key: 'diagnosis',
    sortable: false,
    className: 'listview__col--ellipsis listview__col--w50'
  }]
};

export default listDefinition;