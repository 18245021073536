import { WorkflowDefinition } from '@support/components/workflowView/models';

const workflowDefinition: WorkflowDefinition = {
  className: 'workflow workflow--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Maand',
    key: 'month',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Datum SEH',
    key: 'date',
    expandable: true,
    className: 'workflow__col--w10'
  }, {
    label: '01',
    key: 'hospital01',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '02',
    key: 'hospital02',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '04',
    key: 'hospital04',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '09',
    key: 'hospital09',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '11',
    key: 'hospital11',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '14',
    key: 'hospital14',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '17',
    key: 'hospital17',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '18',
    key: 'hospital18',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '19',
    key: 'hospital19',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: '26',
    key: 'hospital26',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {        
    label: '27',
    key: 'hospital27',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {           
    label: '50',
    key: 'hospital50',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {           
    label: '60',
    key: 'hospital60',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {           
    label: '62',
    key: 'hospital62',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {                                                       
    label: 'Totaal',
    key: 'total',
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Toedracht',
    key: 'cause',
    className: 'workflow__col--ellipsis workflow__col--w25',
  }]
};

export default workflowDefinition;