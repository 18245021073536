
import {ListViewItemData, ListViewDefinition} from './models'

/*
* ListViewItemDataHelper
* 
*/
export default abstract class ListViewItemDataHelper {

  public static transformData(data: any[], definition: ListViewDefinition):ListViewItemData[] {

    // get the keys we want to display as defined in listViewDefinition
    const columns: any[] = [];
    for (const j of definition.columns) {
      columns.push(j.key);
    }

    // extract the specified keys:values from the raw data (as specified in the ListViewDefinition)
    const newData:ListViewItemData[] = [];
    for (const i of data) {
      const item = {
        id: i?.id,
        checked: false,
        data: columns.reduce((obj: object, key: any) => ({ ...obj, [key]: i?.[key] }), {})
      } as ListViewItemData
      newData.push(item)
    }

    return [...newData];
  }
}