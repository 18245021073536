// app
import SubLayout1Col from '@layouts/subLayouts/SubLayout1Col';
import SectionDefault from '@layouts/sections/SectionDefault';

// features
import List from '@features/organizations';

import './index.scss';

export default function Organizations() {
  return (
    <>
      <SectionDefault className="organizations">
        <SubLayout1Col>
          <h1>Organisaties</h1>
          <List />
        </SubLayout1Col>
      </SectionDefault>
    </>
  );
}
