import KeyValue from '@support/models/keyValue'

/**
 * Updates a filter object based on the clicked workflow item.
 * The function will toggle filters when already set so the workflow item
 * collapsed again after clicking an already open item.
 * 
 * The function works as followed
 * 1. find the level of the key
 * 2. toggle/update key
 * 3. clear all deeper level keys 
 * 
 * @param key - the key in the filter to update
 * @param itemFilter - the complete filter of the clicked workflow item
 * @param stateFilter - the current filter in the state
 * @param levels - the levels that are available in the filter
 * @returns - the updated stateFilter
 */
export const updateFilter = (key: string, itemFilter: KeyValue, stateFilter: KeyValue, levels: string[]): KeyValue => {

  const levelIndex = levels.indexOf(key);
  if (levelIndex !== -1) {
    for (let i = levelIndex; i < levels.length; i++) {
      const level = levels[i];
      if (level === key) {
        const filterValue = stateFilter[level];
        stateFilter[level] = filterValue === itemFilter[level] ? '' : itemFilter[level];
      } else {
        stateFilter[level] = '';
      }
    }
  }
  return stateFilter;
}
