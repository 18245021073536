import {Configuration, RedirectRequest } from '@azure/msal-browser';

const APP_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID || '';
const APP_AUTHORITY: string = process.env.REACT_APP_AUTHORITY || '';
const APP_REDIRECT_URL: string = process.env.REACT_APP_REDIRECT_URL || '';
const APP_API_ID_URI: string = process.env.REACT_APP_API_ID_URI || '';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: APP_CLIENT_ID,
        authority: APP_AUTHORITY,
        redirectUri: APP_REDIRECT_URL,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [`${APP_API_ID_URI}/access_as_user`]
    // extraScopesToConsent: ["User.Read"]
};