import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import lisRecordReducer from '@src/features/lisRecord/store/slice';
import lisRecordDataLineageReducer from '@src/features/lisRecordDataLineage/store/slice'
import lisRecordHistoryReducer from '@src/features/lisRecordHistory/store/slice'
import lisRegistrationReducer from '@src/features/lisRegistration/store/slice'
import lisUploadsReducer from '@src/features/lisUploads/store/slice'
import lisWorkflowDateReducer from '@src/features/lisWorkflowDate/store/slice'
import lisWorkflowReasonVisitReducer from '@src/features/lisWorkflowReasonVisit/store/slice'
import moveRegistrationReducer from '@src/features/moveRegistration/store/slice'
import moveUploadsReducer from '@src/features/moveUploads/store/slice'
import moveWorkflowReducer from '@src/features/moveWorkflow/store/slice'
import moveRecordReducer from '@src/features/moveRecord/store/slice'
import moveRecordDataLineageReducer from '@src/features/moveRecordDataLineage/store/slice'
import moveRecordHistoryReducer from '@src/features/moveRecordHistory/store/slice'
import organizationDetailsReducer from '@src/features/organizationDetails/store/slice'
import organizationsReducer from '@src/features/organizations/store/slice'
import notificationsReducer from '@features/notifications/slice'
import cacheReducer from '@features/cache/slice'

export const store = configureStore({
  reducer: {
    lisRecord: lisRecordReducer,
    lisRecordDataLineage: lisRecordDataLineageReducer,
    lisRecordHistory: lisRecordHistoryReducer,
    lisRegistration: lisRegistrationReducer,
    lisUploads: lisUploadsReducer,
    lisWorkflowDate: lisWorkflowDateReducer,
    lisWorkflowReasonVisit: lisWorkflowReasonVisitReducer,
    moveRegistration: moveRegistrationReducer,
    moveRecord: moveRecordReducer,
    moveRecordDataLineage: moveRecordDataLineageReducer,
    moveRecordHistory: moveRecordHistoryReducer,
    moveUploads: moveUploadsReducer,
    moveWorkflow: moveWorkflowReducer,
    organizationDetails: organizationDetailsReducer,
    organizations: organizationsReducer,
    notifications: notificationsReducer,
    cache: cacheReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
