import { FormField, FormFieldSelect, FormFieldRule, FormValue, FormValueRule } from '@src/support/components/form/models';

interface FormFieldProps {
  fieldId: number | string
  description: string
  formFields: FormField[]
  fieldRules: FormFieldRule[]
}

interface FormFieldSelectProps extends FormFieldProps {
  values: FormValue[]
  valueRules: FormValueRule[]
}

export function getFormField({ fieldId, description, formFields, fieldRules }: FormFieldProps) {
  const field = formFields.find(field => field.id === fieldId) ?? {} as FormField;
  const fieldRulesFiltered = fieldRules.filter(condition => condition.fieldId === Number(fieldId))

  return {
    ...field,
    ...fieldRulesFiltered.length ? { fieldRules: fieldRulesFiltered } : {},
    description: description
  } as FormField;
}

export function getFormSelect({ fieldId, description, formFields, values, fieldRules, valueRules }: FormFieldSelectProps) {
  const field = getFormField({ fieldId, description, formFields, fieldRules });
  const optionValues = values.filter(value => value.listId === field?.listId) ?? [];
  const valueRulesFiltered = valueRules.filter(condition => condition.fieldId === fieldId);

  return {
    ...field,
    options: optionValues,
    valueRules: valueRulesFiltered,
  } as FormFieldSelect
}

export function getValueLabel(value: number | undefined, selectOptions: FormFieldSelect) {
  if (!value || value === undefined) return '';
  const label = selectOptions.options.find(x => x.id === Number(value))?.label || '';
  // show raw value if the value is not one of the selectOptions
  if (label === '') return value.toString();
  // Remove number and dot
  let modifiedLabel = label.replace(/^\d+\.\s/, '');
  // Capitalize the first letter of the modified string
  modifiedLabel = modifiedLabel.charAt(0).toUpperCase() + modifiedLabel.slice(1);
  return modifiedLabel;
}