import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { useCallback } from 'react';
import Notification from './components/notification';
import { NotificationObject } from './models';
import './index.scss';

import {
    startDismissAnimation,
    removeNotification,
} from './slice';

export default function Notifications() {

    const dispatch = useAppDispatch();
    const {
        notifications,
    } = useAppSelector(state => state.notifications)

    const onDismiss = useCallback((id: number | undefined) => {
        dispatch(startDismissAnimation(id));
        setTimeout(() => {
            dispatch(removeNotification(id));
        }, 250)
    }, [dispatch]);

    return (
        <div className="notification-wrapper">
            {notifications.map((notification: NotificationObject) => (
                <Notification key={notification.id} notification={notification} onDismiss={onDismiss}>
                    {notification.text}
                </Notification>
            ))}
        </div>
    );
}