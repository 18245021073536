import React, {
  useEffect, useState, useRef, ComponentProps, useCallback, memo
} from 'react';
import useKeyPresses from '@support/hooks/useKeyPresses';
import useHasFocus from '@support/hooks/useHasFocus';
import './index.scss';

export type ComboBoxItem = {
  label: string,
  value: string
};

export interface Props extends ComponentProps<'input'> {
  value: string[],
  className?: string,
  placeholderText?: string,
  noResultText?: string,
  searchText?: string,
  multiSelect?: boolean,
  items: ComboBoxItem[],
  onChange?: (value: any) => void
  onBlur?: (value: any) => void
}

function ComboBox({
  value, className = '', placeholderText = 'Selecteer', searchText, noResultText, multiSelect = false, items, onChange, onBlur,
}: Props) {

  // element references
  const componentRef = useRef<HTMLDivElement>(null);
  const inpuRef = useRef<HTMLInputElement>(null);
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const hasFocus = useHasFocus(componentRef);

  // states
  const [filteredItems, setFilteredItems] = useState(items);
  const [filterValue, setFilterValue] = useState('');
  const [curLabel, setCurLabel] = useState<string | undefined>();
  const focusIndex = useRef(0);

  const setFocus = (index: number) => {
    focusIndex.current = Math.max(Math.min(index, buttonRefs.current.length), 0);
    const el = buttonRefs.current[focusIndex.current];
    if (el instanceof HTMLElement) {
      el.focus();
    }
  };

  useKeyPresses('ArrowDown', useCallback((e) => {
    if (hasFocus) {
      setFocus(focusIndex.current + 1);
      e.preventDefault();
    }
  }, [hasFocus]));

  useKeyPresses('ArrowUp', useCallback((e) => {
    if (hasFocus) {
      setFocus(focusIndex.current - 1);
      e.preventDefault();
    }
  }, [hasFocus]));

  // handle enter key selection
  useKeyPresses('Enter', (e) => {
    // key up, do nothing
  }, useCallback((e:KeyboardEvent) => {
    if (hasFocus) {
      if(filteredItems.length === 1) {
        setFocus(0);
      } else if(focusIndex.current === -1) {
        e.preventDefault(); // prevent form from submitting
      }
    }
  }, [hasFocus, filteredItems]));

  const selectItem = useCallback((item: ComboBoxItem) => {

    let newValue: string[];
    if (value?.includes(item.value)) {
      newValue = value.filter((i) => i !== item.value);
    } else {
      newValue = multiSelect ? [...value, item.value] : [item.value];
    }

    if (!multiSelect && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    if (onChange) {
      onChange(newValue);
    }

  }, [multiSelect, value, onChange]);

  useEffect(() => {
    if (hasFocus) {
      inpuRef.current?.focus();
      focusIndex.current = -1;
    }
  }, [hasFocus]);

  /**
   * show selected item and label
   */
  useEffect(() => {
    const labels: string[] = [];
    value.forEach((v) => {
      items.forEach((i) => {
        if (v === i.value) {
          labels.push(i.label);
        }
      });
    });

    const labelString = labels.join(', ');
    setCurLabel(labelString || undefined);

  }, [value, items]);

  useEffect(() => {
    const newFilteredItems = items.filter((item) => {
      // %SYN% marks te point where the synonyms start, these are not displayed in the label
      // but are searchable
      return item.label.replace('%SYN%', ' ').toLowerCase().includes(filterValue.toLowerCase())
    });
    setFilteredItems(newFilteredItems);
  }, [filterValue, items]);

  return (
    <div className={`combo-box ${className} ${hasFocus ? 'combo-box--expanded' : 'combo-box--collapsed'} ${multiSelect ? 'combo-box--multi-select' : ''}`} ref={componentRef}>
      <div className={`combo-box__filter ${hasFocus ? '' : 'hidden'}`}>
        <input ref={inpuRef} type="text" value={filterValue} placeholder={searchText || 'Zoek'} onChange={(event) => setFilterValue(event.target.value)} />
      </div>
      <div className={`combo-box__opener ${hasFocus ? 'hidden' : ''}`}>
        {curLabel ? <button>{curLabel.split('%SYN%')[0]}</button> : <button className="combo-box__placeholder">{placeholderText}</button>}
      </div>
      {value.length > 1 && (
        <span className="combo-box__num-selected">{value.length}</span>
      )}
      {hasFocus === true && (
        <div className="combo-box__dropdown">
          {filteredItems.map((item: ComboBoxItem, index: number) => (            
            <button key={item.value} tabIndex={-1} ref={(el) => { buttonRefs.current[index] = el; }} className={`
              ${item.value === '__RESET__' ? 'reset' : value?.includes(item.value) ? 'active' : ''}
            `} onMouseDown={(e) => e.preventDefault()} onClick={(e) => { selectItem(item); }
            }>{item.label.split('%SYN%')[0]}</button>
          ))}
          {!filteredItems.length && (
            <div className="combo-box__noresult">{noResultText || 'Geen resultaat...'}</div>
          )}
        </div>
      )}

    </div>
  );
}

export default memo(ComboBox);