import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { FormData, SubmitEventType } from './models';
import FormEvents from './FormEvents';
import './index.scss';

interface Props {
  children: ReactNode
  defaultValues: FormData
  onDirtyEvent: () => void,
  onChangeEvent: (data: FormData) => void,
  onSubmitEvent: (type: SubmitEventType, data: FormData) => void
}

export default function Form({ children, defaultValues = {}, onDirtyEvent, onChangeEvent, onSubmitEvent }: Props) {

  const methods = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues])
  })

  const reset = methods.reset;
  const trigger = methods.trigger;

  useEffect(() => {
    reset({ ...defaultValues })
    // when records are already edited/touched, trigger validation on load
    if(!defaultValues.readOnly && defaultValues.touched === true) {
      setTimeout(() => {
        trigger();
      })
    }
  }, [trigger, defaultValues, reset]);
 
  const onChangeHandler = useCallback((data: object) => {
    const values = data as FormData
    onChangeEvent(values)
  }, [onChangeEvent])

  const onDirtyHandler = useCallback(() => {
    onDirtyEvent()
  }, [onDirtyEvent])

  const onSubmitSuccess: SubmitHandler<FormData> = (data: any, event: any) => {
    onSubmitEvent(SubmitEventType.SUCCESS, data);
  }

  const onSubmitError: SubmitHandler<FormData> = (data: any, event: any) => {
    onSubmitEvent(SubmitEventType.ERROR, data);
  }

  return (
    <FormProvider {...methods}>
      <FormEvents onDirty={onDirtyHandler} onChange={onChangeHandler} />
      <form onSubmit={methods.handleSubmit(onSubmitSuccess, onSubmitError)}>
        {children}
      </form>
    </FormProvider>
  );
}

