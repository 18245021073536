import FormRow from '@src/support/components/form/FormRow';
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import InputField from '@src/support/components/inputField';
import FormFieldset from '@src/support/components/form/FormFieldset';
import { useAppSelector } from '@src/app/store/hooks';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormValue, FormValueRule, FormFieldSelect } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

type Props = {
  readOnly?: boolean,
}

export default function Record({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();
 
  const {
    cache,
  } = useAppSelector(state => state.cache)

  const {
    record,
  } = useAppSelector(state => state.moveRecord)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_4 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 4, description: 'Provincie', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_7 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 7, description: 'Plaatsnaam', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_8 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 8, description: 'Straatnaam', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_6 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 6, description: 'Huisnummer', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_5 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 5, description: 'Postcode', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_9 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 9, description: 'Breedtegraad', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_10 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 10, description: 'Lengtegraad', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  const hasLocationOngevalText = record?.type === 'SEH' && record[80]?.length;
  
  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Locatie</legend>
      {hasLocationOngevalText ? (
      <FormRow fieldId="80" isActive={true} classNames={{leftColumn: 'col-12', rightColumn: 'd-none'}}>
        <span>{record[80]}</span>
        <></>
      </FormRow>
      ) : ''}
      <FormRow fieldId="4" isActive={visibility[4]} fieldRules={field_4.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_4.current.description}</span>
        <Controller
          control={methods.control}
          name="4"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_4.current.options} valueRules={field_4.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="7" isActive={visibility[7]} fieldRules={field_4.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_7.current.description}</span>
        <InputField name="7" error={methods.formState.errors[7] as FieldError} config={{ required: false }} />
      </FormRow>
      <FormRow fieldId="8" isActive={visibility[8]} fieldRules={field_8.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_8.current.description}</span>
        <InputField name="8" error={methods.formState.errors[8] as FieldError} config={{ required: false }} />
      </FormRow>
      <FormRow fieldId="6" isActive={visibility[6]} fieldRules={field_6.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_6.current.description}</span>
        <InputField name="6" error={methods.formState.errors[6] as FieldError} config={{ required: false }} />
      </FormRow>
      <FormRow fieldId="5" isActive={visibility[5]} fieldRules={field_5.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_5.current.description}</span>
        <InputField name="5" error={methods.formState.errors[5] as FieldError} config={{ required: false }} />
      </FormRow>
      <FormRow fieldId="9" isActive={visibility[9]} fieldRules={field_9.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_9.current.description}</span>
        <InputField name="9" error={methods.formState.errors[9] as FieldError} config={{ required: false }} />
      </FormRow>
      <FormRow fieldId="10" isActive={visibility[10]} fieldRules={field_10.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_10.current.description}</span>
        <InputField name="10" error={methods.formState.errors[10] as FieldError} config={{ required: false }} />
      </FormRow>
    </FormFieldset>
  )
}