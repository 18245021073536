import { useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

type Props = {
  readOnly?: boolean,
}

export default function WorkAccident({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  const field_47 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 47,
    description: 'Bedrijfstak',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));      

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Bedrijfsongeval</legend>
      <FormRow fieldId="47" isActive={visibility[47]} fieldRules={field_47.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_47.current.description}</span>
        <Controller
          control={methods.control}
          name="47"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_47.current.options} valueRules={field_47.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}