import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';

import { useAppSelector } from '@src/app/store/hooks';

type Props = {
  readOnly?: boolean,
}

export default function AmbulanceText({ readOnly = false }: Props) { 
  const {
    record,
  } = useAppSelector(state => state.moveRecord)

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Ambulancetekst</legend>
      <FormRow fieldId="76" isActive={true} classNames={{leftColumn: 'col-12', rightColumn: 'd-none'}}>
        <span>{record?.[76]}</span>
        <></>
      </FormRow>
    </FormFieldset>
  )
}