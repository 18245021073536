
import { NavLink } from 'react-router-dom'
import './index.scss'

export default function Navigation() {

    const closeMenu = (menuId: string) => {
        const menuEl = document.querySelector(`#${menuId} > ul`) as HTMLElement
        if (menuEl) {
            menuEl.style.display = 'none';
            setTimeout(() => {
                menuEl.style.display = 'inherit';
            }, 500)
        }
    }

    return (
        <ul className="navigation">
            <li>
                <div tabIndex={0} id="menu-lis" className="navigation__button navigation__button--dropdown">
                    <span>LIS</span>
                    <ul>
                        <li><NavLink onClick={() => closeMenu('menu-lis')} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/lis/uploads">Uploads</NavLink></li>
                        <li><NavLink onClick={() => closeMenu('menu-lis')} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/lis/registration">Registratie</NavLink></li>
                        <li><NavLink onClick={() => closeMenu('menu-lis')} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/lis/workflow">Workflow</NavLink></li>
                    </ul>
                </div>
            </li>
            <li>
                <div tabIndex={0} id="menu-move" className="navigation__button navigation__button--dropdown">
                    <span>MOVE</span>
                    <ul>
                        <li><NavLink onClick={() => closeMenu('menu-move')} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/move/uploads">Uploads</NavLink></li>
                        <li><NavLink onClick={() => closeMenu('menu-move')} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/move/registration">Registratie</NavLink></li>
                        <li><NavLink onClick={() => closeMenu('menu-move')} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/move/workflow">Workflow</NavLink></li>
                    </ul>
                </div>
            </li>
            <li>
                <div className="navigation__button">
                    <span>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/organizations">Organisaties</NavLink>
                    </span>
                </div>
            </li>
        </ul >
    );
}
