import { useAppDispatch } from '@app/store/hooks';

// features
import ExportControls from '@features/organizationDetails/components/exportControls';
import { NotificationObject, NotificationType } from '@features/notifications/models';
import { OrganizationDetailsResponse } from '@features/organizationDetails/models/models';
import { addNotification } from '@features/notifications/slice';

// support
import DateHelper from '@src/support/helpers/dateHelper';
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';

export interface Props {
    organization: OrganizationDetailsResponse
}

export default function MoveExports({ organization }: Props) {
    const dispatch = useAppDispatch();

    const downloadFile = (startDate: Date, endDate: Date, file: string) => {
        const startDateString = startDate.toLocaleDateString(DateHelper.DEFAULT_LOCALE);
        const endDateString = endDate.toLocaleDateString(DateHelper.DEFAULT_LOCALE);

        const info: NotificationObject = {
            type: NotificationType.INFO,
            text: `ID: ${organization.id} ~ ${file} ~ ${startDateString} - ${endDateString}`,
            autoDismissMilliseconds: 3000
        }

        dispatch(addNotification(info));
    };

    return (
        <FormFieldset className={'block mb-4'}>
            <legend className="h2">
                Bestanden
            </legend>
            <FormRow fieldId="discussFile" isActive={true}>
                <span>Bespreken</span>
                <ExportControls onSubmit={(s, e) => downloadFile(s, e, 'bespreken')} />
            </FormRow>
            <FormRow fieldId="exclusionFile" isActive={true}>
                <span>Exclusie</span>
                <ExportControls onSubmit={(s, e) => downloadFile(s, e, 'exclusie')} />
            </FormRow>
        </FormFieldset>
    );
}
