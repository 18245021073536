// A mock function to mimic making an async request for data
import { AxiosApi } from '@src/index';
import { AxiosResponse } from 'axios';
const responseBody = (response: AxiosResponse): any => response.data
const apiBaseUrl = '/api/move';

const requests = {
    get: (url: string, params?: URLSearchParams) => AxiosApi.get(url, { params }).then(responseBody),
    post: (url: string, body: object) => AxiosApi.post(url, body).then(responseBody),
    put: (url: string, body: object) => AxiosApi.put(url, body).then(responseBody),
    delete: (url: string) => AxiosApi.delete(url).then(responseBody),
}

const moveRecordDataLineageAPI = {
    list: (id: string, params: URLSearchParams) => requests.get(`${apiBaseUrl}/registrations/${id}/datalineage`, params)
}

export default moveRecordDataLineageAPI