
export enum PopupType {
  INFO = 0,
  WARNING
}

export interface PopupObject {
  type: PopupType,
  title: string,
  text: string,
  buttonConfirm?: string,
  onConfirm?: () => void,
  buttonCancel?: string,
  onCancel?: () => void,
  dismissable?: boolean,
  cssClass?:string | undefined,
}