import { ListViewDefinition } from '@support/components/listView/models';

const listDefinition: ListViewDefinition = {
    hasCheckBox: false,
    hasLink: false,
    className: 'listview--lis listview--norwap listview--sticky-header',
    columns: [{
        label: 'Datum',
        key: 'date',
        sortable: true
    }, {
        label: 'Mutatie',
        key: 'mutation',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w75'
    }, {
        label: 'Partij',
        key: 'party',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w25'
    }]
};

export default listDefinition;