
/*
* dateHelper
* 
*/
export default abstract class dateHelper {

  public static readonly DEFAULT_LOCALE = 'nl-NL';

  public static dateToTimeStamp = (date:(Date | null)):string => {
    if(date) return Math.floor(date.valueOf() / 1000).toString();
    return '';
  }

  public static timeStampToDate = (param:(string | null)):(Date | null) => {
    if(!param) return null;
    const d = new Date(parseInt(param) * 1000);
    return dateHelper.isValidDate(d) ? d :  null;
  }

  public static isValidDate = (d:any):boolean => {
    return d instanceof Date && !isNaN(d.valueOf());
  }
}