import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function EnvironmentalFactors({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();
  
  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_22 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 22, description: 'Te water', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_25 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 25, description: 'Gladheid', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_26 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 26, description: 'Weer', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_27 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 27, description: 'Infra', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_28 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 28, description: 'Product/materiaal', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Omgevingsfactoren</legend>
      <FormRow fieldId="22" isActive={visibility[22]} fieldRules={field_22.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_22.current.description}</span>
        <Controller
          control={methods.control}
          name="22"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_22.current.options} valueRules={field_22.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="25" isActive={visibility[25]} fieldRules={field_25.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_25.current.description}</span>
        <Controller
          control={methods.control}
          name="25"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_25.current.options} valueRules={field_25.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="26" isActive={visibility[26]} fieldRules={field_26.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_26.current.description}</span>
        <Controller
          control={methods.control}
          name="26"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_26.current.options} valueRules={field_26.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="27" isActive={visibility[27]} fieldRules={field_27.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_27.current.description}</span>
        <Controller
          control={methods.control}
          name="27"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_27.current.options} valueRules={field_27.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="28" isActive={visibility[28]} fieldRules={field_28.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_28.current.description}</span>
        <Controller
          control={methods.control}
          name="28"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_28.current.options} valueRules={field_28.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}