import { ComponentProps, useRef, useState, useLayoutEffect, ChangeEvent } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import InputWrapper from '../inputWrapper';
import './index.scss';

interface Props extends ComponentProps<'textarea'> {
  maxLength?: number,
  className?: string,
  error?: FieldError,
  onChange?: (e: ChangeEvent) => void
}

const MIN_TEXTAREA_HEIGHT = 32;

export default function TextArea({ className, error, onChange, ...rest }: Props) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [text, setText] = useState(rest.value);

  useLayoutEffect(() => {
    textAreaRef.current!.style.height = 'inherit';
    textAreaRef.current!.style.height = `${Math.max(
      textAreaRef.current!.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [text]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(event.target.value);
		
    if (!onChange) return;
    onChange(event);
	};

  return (    
    <>
      <InputWrapper error={error} className={`text-field ${className ?? ''}`}>
        <textarea
          data-value={rest.value} // css selector to hide the field in disabled state...
          ref={textAreaRef}
          rows={2}
          style={{
            minHeight: MIN_TEXTAREA_HEIGHT
          }}
          onChange={onChangeHandler}
          {...rest}
        />
      </InputWrapper>
    </>
  );
  }