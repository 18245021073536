import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import organizationDetailsAPI from '@features/organizationDetails/api/api';
import { OrganizationDetailsResponse } from '@features/organizationDetails/models/models';

export interface OrganizationDetailsState {
    status: 'idle' | 'loading' | 'failed';
    organization: OrganizationDetailsResponse | undefined,
}

const initialState: OrganizationDetailsState = {
    status: 'idle',
    organization: undefined,
};

export const fetchOrganizationDetailsAsync = createAsyncThunk<OrganizationDetailsResponse, { id: string }, { state: RootState }>(
    'organizationDetails/fetchOrganizationDetailsAsync',
    async (payload, thunkAPI,) => {
        try {
            return await organizationDetailsAPI.details(payload.id);
        } catch (error: any) {
            if (!error.response) throw error
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

export const organizationDetailsSlice = createSlice({
    name: 'organizationDetails',
    initialState,
    reducers: {
        setAutomaticProcessing: (state, action: PayloadAction<boolean>) => {
            if (state.organization) {
                state.organization.autoProcessing = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationDetailsAsync.pending, (state) => {
                state.organization = undefined;
                state.status = 'loading';
            })
            .addCase(fetchOrganizationDetailsAsync.fulfilled, (state, action) => {
                state.organization = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchOrganizationDetailsAsync.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

export const  { setAutomaticProcessing } = organizationDetailsSlice.actions;

export default organizationDetailsSlice.reducer
