import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// support
import Button from '@support/components/button';

import './index.scss';

export interface Props {
    title: string;
    navigationButton?: {
        title: string
        url: string
    }
}

export default function TitleHeader({ title, navigationButton }: Props) {
    const navigate = useNavigate();

    const onClick = useCallback(() => {
        if (navigationButton?.url) {
            navigate(navigationButton.url);
        }
    }, [navigate, navigationButton]);

    return (
        <div className="title-header">
            <h1>{title}</h1>
            {navigationButton ? <Button className="button--navigation" onClick={onClick}>{navigationButton.title}</Button> : null}
        </div>
    );
}
