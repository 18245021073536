
import { ListViewDefinition } from '@support/components/listView/models';

/**
 * Define what columns from the API data to show and 
 * what properties they have
 */
const listDefinition: ListViewDefinition = {
  hasCheckBox: false,
  hasLink: true,
  className: 'listview--move listview--norwap listview--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    sortable: true,
    className: 'listview__col--mw-115'
  }, {
    label: 'Organisatie',
    key: 'organization',
    sortable: true,
    className: 'listview__col--mw-115'
  }, {
    label: 'Volgnummer',
    key: 'trackingId',
    sortable: true,
    className: 'listview__col--ellipsis listview__col--mw-115'
  }, {
    label: 'Datum',
    key: 'date',
    sortable: true,
    className: 'listview__col--mw-115'
  }, {
    label: 'Type ongeval',
    key: 'category',
    sortable: true
  }, {
    label: 'Toedracht',
    key: 'cause',
    sortable: false,
    className: 'listview__col--ellipsis listview__col--w100'
  }, {
    label: 'Gewijzigd',
    key: 'changeDate',
    sortable: true
  }, {
    label: 'Gebruiker',
    key: 'user',
    sortable: true
  }]
};

export default listDefinition;