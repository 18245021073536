import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import lisWorkflowReasonVisitAPI from '@features/lisWorkflowReasonVisit/api/api';
import { LisWorkflowReasonVisitFilter } from '@features/lisWorkflowReasonVisit/models/models'

// support
import { WorkflowItemObj } from '@support/components/workflowView/models';
import { updateFilter } from '@support/components/workflowView/helper';
import KeyValue from '@support/models/keyValue'

export interface LisWorkflowReasonVisitState {
  status: 'idle' | 'loading' | 'failed',
  items: WorkflowItemObj[],
  path: KeyValue,
  filter: LisWorkflowReasonVisitFilter
}

const initialState: LisWorkflowReasonVisitState = {
  status: 'idle',
  items: [],
  path: {},
  filter: {
    status: '',
    month: '',
    hospitalId: '',
    startDate: '',
    endDate: '',
    query: ''
  }
};

export const fetchLisWorkflowReasonVisitAsync = createAsyncThunk<WorkflowItemObj[], void, { state: RootState }>(
  'lisWorkflowReasonVisit/fetchLisWorkflowReasonVisitAsync',
  async (_, thunkAPI) => {

    const filter = thunkAPI.getState().lisWorkflowReasonVisit.filter;
    const params = new URLSearchParams();
    if (filter.status) params.append('status', filter.status);
    if (filter.hospitalId) params.append('hospitalId', filter.hospitalId);
    if (filter.month) params.append('month', filter.month);
    if (filter.startDate) params.append('startDate', filter.startDate);
    if (filter.endDate) params.append('endDate', filter.endDate);
    if (filter.query) params.append('query', filter.query);

    try {
      return await lisWorkflowReasonVisitAPI.workflow(params);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const lisWorkflowReasonVisitSlice = createSlice({
  name: 'lisWorkflowReasonVisit',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPath: (state, action: PayloadAction<{ key: string, path: KeyValue }>) => {
      const levels: string[] = ['status', 'hospitalId', 'month'];
      updateFilter(action.payload.key, action.payload.path, state.filter, levels)
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.filter.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.filter.endDate = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      if (state.filter.query !== action.payload) {
        state.filter.query = action.payload;
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // --------------------------------------------------------------------------
      // workflow
      .addCase(fetchLisWorkflowReasonVisitAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLisWorkflowReasonVisitAsync.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchLisWorkflowReasonVisitAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setPath, setStartDate, setEndDate, setQuery } = lisWorkflowReasonVisitSlice.actions;

export default lisWorkflowReasonVisitSlice.reducer;
