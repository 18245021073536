import { WorkflowItemObj, WorkflowDefinition, WorkflowCol, WorkflowEvent, WorkflowEventType } from './models';
import './index.scss';
import { useCallback, memo, useState, useEffect } from 'react';

/**
 * WorkflowItem
 * This component displays  is used recursively by itself
 */
interface WorkflowItemProps {
  item: WorkflowItemObj,
  level: number,
  definition: WorkflowDefinition,
  className?: string,
  onWorkflowEvent: (event: WorkflowEvent) => void,
}

const WorkflowItem = memo(
  function WorkflowItem({ item, level, definition, onWorkflowEvent, className = '' }: WorkflowItemProps) {

    /**
     * Send click event event to parent so the model can be updated
     */
    const onClick = useCallback((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: WorkflowItemObj, level: number) => {
      // check if this level is expandable / clickable
      const columnDefinition = definition.columns[level]; // we assume column === level
      if (columnDefinition.expandable) {
        const event: WorkflowEvent = {
          event: WorkflowEventType.TOGGLE,
          key: columnDefinition.key,
          path: item.path
        }
        onWorkflowEvent(event);
      } else if (columnDefinition.clickable && item.data.id) {
        const event: WorkflowEvent = {
          event: WorkflowEventType.CLICK,
          id: item.data.id
        }
        onWorkflowEvent(event);
      }
    }, [onWorkflowEvent, definition]);

    return (
      <>
        <tr className={`workflow__item ${className} ${item.items?.length ? 'workflow__item--expanded' : ''} ${item.data.id && definition.columns[level].clickable ? 'workflow__item--clickable' : ''}`} onClick={(e) => onClick(e, item, level)} data-level={level}>
          {Object.keys(definition.columns).map((i: string, idx: number) => (
            <td key={definition.columns[idx].key} className={definition.columns[idx].className}><div className={`${(idx === level && definition.columns[idx].expandable) ? 'workflow__item--dropdown-icon' : ''}`}>{item.data[definition.columns[idx].key]}</div></td>
          ))}
          <td></td>
        </tr>
        {item.items && item.items.map((item: WorkflowItemObj) => (
          <WorkflowItem key={item.id} level={level + 1} definition={definition} className="workflow__nested" item={item} onWorkflowEvent={onWorkflowEvent} />
        ))}
      </>
    )
  }
);

/**
 * Workflow
 * The main component. Renders the header and 
 */
interface WorkflowProps {
  items: WorkflowItemObj[],
  definition: WorkflowDefinition,
  className?: string,
  onWorkflowEvent: (event: WorkflowEvent) => void,
}

export default function Workflow({ items, definition, onWorkflowEvent, className = '' }: WorkflowProps) {

  const [totals, setTotals] = useState<string[]>(Array(definition.columns.length).fill(''))

  /**
   * Generate totals row
   */
  useEffect(() => {
    const totalsTemp = Array(definition.columns.length).fill('');
    setTotals(totalsTemp);
    let index = 0;
    for (const col of definition.columns) {
      if (col.showTotal) {
        let total = 0;
        for (const item of items) total += parseFloat(item.data[col.key]) | 0;
        totalsTemp[index++] = total.toString();
      } else {
        totalsTemp[index++] = '';
      }
    }
    totalsTemp[0] = 'Totaal';
  }, [items, definition.columns])

  return (
    <div className={`workflow ${definition.className || ''}`}>
      <table>
        <thead>
          <tr>
            {definition.columns.map((item: WorkflowCol) => (
              <th key={item.key} className={item.className}><div>{item.label}</div></th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: WorkflowItemObj) => (
            <WorkflowItem key={item.id} level={0} item={item} definition={definition} onWorkflowEvent={onWorkflowEvent} />
          ))}
          <tr className="workflow__totals">
            {totals.map((item: string, idx: number) => (
              <td key={idx} className={definition.columns[idx]?.className}><div>{item}</div></td>
            ))}
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}