import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';

// features
import Filter from '@features/moveWorkflow/components/filter';

import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';

// support
import Throbber from '@support/components/throbber';
import WorkflowView from '@support/components/workflowView';
import { WorkflowDefinition, WorkflowEvent, WorkflowEventType } from '@support/components/workflowView/models';

// store
import {
  fetchWorkflowAsync,
  setPath,
  setItems,
} from '@features/moveWorkflow/store/slice'

/**
 * Define what columns from the API data to show and 
 * what properties they have
 */
import workflowDefinitions from './workflowDefinitions';
import workflowDefinitionTotal from './workflowDefinitions/workflowDefinitionTotal';

export default function Workflow() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    items,
    status,
    filter,
  } = useAppSelector(state => state.moveWorkflow)

  const [
    activateWorkflowDefinition,
    setActivateWorkflowDefinition
  ] = useState<WorkflowDefinition>(workflowDefinitionTotal);

  /**
   * Filter has changed, get new items
   */
  useEffect(() => {
    // Show 'no result' message when a region is selected that has no workflow definition.
    // This can happen if the database contains entries for a certain region, but the
    // workflow definition in the frontend hasn't been configured and/or deployed yet.
    if (!(filter.region in workflowDefinitions)) {
      dispatch(setItems([]));
      return;
    }
    
    const workflowDefinition = workflowDefinitions[filter.region];
    setActivateWorkflowDefinition(workflowDefinition);

    dispatch(fetchWorkflowAsync()).unwrap().catch(() => {
      dispatch(addNotification({
        type: NotificationType.WARNING,
        text: 'Fout bij ophalen van de data...',
        autoDismissMilliseconds: 3000
      }));
    });
  }, [dispatch, filter])

  /**
   * Handle list view events
   */
  const onWorkflowEvent = useCallback((e: WorkflowEvent) => {
    switch (e.event) {
      case WorkflowEventType.CLICK:
        if (e.id) navigate(`/move/registration/${e.id}`);
        break;
      case WorkflowEventType.TOGGLE:
        if (e.key && e.path) {
          dispatch(setPath({ key: e.key, path: e.path }));
        }
        break;
    }
  }, [navigate, dispatch]);

  return (
    <>
      <Filter />
      <Throbber visible={status === 'loading'} />
      {items.length === 0 ? (
        <>
          {status !== 'loading' &&
            <div className="listview__no-result">
              <div>Geen resultaten</div>
            </div>
          }
        </>
      ) : (
        <WorkflowView onWorkflowEvent={onWorkflowEvent} items={items} definition={activateWorkflowDefinition} />
      )}
    </>
  )
}
