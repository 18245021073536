import { ComponentProps, ReactNode } from 'react';
import './index.scss';

interface Props extends ComponentProps<'button'> {
  children?: ReactNode,
  className?: string
}

export default function Button({ children, className = '', ...rest }: Props) {
  return (
    <button className={`button ${className}`} {...rest}>
      {children}
    </button>
  )
}
