
import axios, { AxiosRequestConfig } from 'axios';
import { AuthenticationResult, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';

// because we can have multiple concurrent Api calls requiring token calls
// we save one promise that all concurrent calls will use so everything stays in sync.
let tokenPromise: Promise<void | AuthenticationResult> | null;
const getTokenSilent = async (p:any, msalInstance:PublicClientApplication) => {
    if(!tokenPromise) tokenPromise = msalInstance.acquireTokenSilent(p).catch(error => {
        // fallback to popup when silent call fails
        return msalInstance.acquireTokenPopup(p).catch(error => {
            // fallback to redirect when silent popup fails
            return msalInstance.acquireTokenRedirect(p)
        });
    });
    const response = await tokenPromise;
    tokenPromise = null;
    return response;
}

class AxiosMsal {
    instance: any;
    constructor(msalInstance: PublicClientApplication, loginRequest: RedirectRequest, apiBaseUrl: string) {
        this.instance = axios.create({
            baseURL: apiBaseUrl,
            headers: { 'Content-Type': 'application/json' }
        });

        this.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {

            const inst = msalInstance;
            const acc = msalInstance.getAllAccounts();
            const accessTokenRequest = {
                ...loginRequest,
                account: acc[0],
            };
            
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
            // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2884
            const accessTokenResponse = await getTokenSilent(accessTokenRequest, inst);

            if (config.data) config.data = JSON.stringify(config.data);
            if (accessTokenResponse) {
                const configObj = {
                    ...config,
                    headers: {
                        'Authorization': `Bearer ${accessTokenResponse.accessToken}`,
                        'Content-Type': 'application/json'
                    }
                };
                return await Promise.resolve(configObj);
            }
        }, (error: any) => {
            return Promise.reject(error);
        });
    }
}

export default AxiosMsal;