import { WorkflowDefinition } from '@src/support/components/workflowView/models';
import workflowDefinitionDrenthe from './workflowDefinitionDrenthe';
import workflowDefinitionGroningen from './workflowDefinitionGroningen';
import workflowDefinitionLimburg from './workflowDefinitionLimburg';
import workflowDefinitionNoordBrabant from './workflowDefinitionNoordBrabant';
import workflowDefinitionNoordHolland from './workflowDefinitionNoordHolland';
import workflowDefinitionFriesland from './workflowDefinitionFriesland';
import workflowDefinitionTotal from './workflowDefinitionTotal';
import workflowDefinitionUtrecht from './workflowDefinitionUtrecht';

interface WorkflowDefinitions {
    [key: string]: WorkflowDefinition
}

export default {
    'Drenthe': workflowDefinitionDrenthe,
    'Friesland': workflowDefinitionFriesland,
    'Groningen': workflowDefinitionGroningen,
    'Limburg': workflowDefinitionLimburg,
    'Noord-Brabant': workflowDefinitionNoordBrabant,
    'Noord-Holland': workflowDefinitionNoordHolland,
    'Utrecht': workflowDefinitionUtrecht,
    'Total': workflowDefinitionTotal
} as WorkflowDefinitions