import { useCallback, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import DatePicker, { DateRange } from '@support/components/datePicker';
import ComboBox, { ComboBoxItem } from '@support/components/comboBox';
import DateHelper from '@support/helpers/dateHelper'
import SearchField from '@support/components/searchField';

import './index.scss';

import {
  setStatus,
  setOrganization,
  setStartDate,
  setEndDate,
  setQuery,
} from '@features/lisUploads/store/slice';

export default function UploadsFilter() {

  const {
    filter,
  } = useAppSelector(state => state.lisUploads)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const dispatch = useAppDispatch();

  const queryTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [localQuery, setLocalQuery] = useState<string>(filter.query);
  const [localStartDate, setLocalStartDate] = useState<Date | null>(DateHelper.timeStampToDate(filter.startDate));
  const [localEndDate, setLocalEndDate] = useState<Date | null>(DateHelper.timeStampToDate(filter.endDate));

  // get filter options from cache
  const statusComboBoxItems = useRef<ComboBoxItem[]>(cache.lisUploadsFilters.statuses || []);
  const organizationComboBoxItems = useRef<ComboBoxItem[]>(cache.lisUploadsFilters.organizations || []);

  const updateStatus = useCallback((status: string[]) => {
    dispatch(setStatus(status))
  }, [dispatch])

  const updateOrganization = useCallback((organization: string[]) => {
    dispatch(setOrganization(organization))
  }, [dispatch])

  const updateQuery = useCallback((query: string) => {
    setLocalQuery(query);
    if (queryTimeout.current) clearTimeout(queryTimeout.current);
    queryTimeout.current = setTimeout(() => {
      dispatch(setQuery(query))
    }, 500);
  }, [dispatch])

  const handleDateChange = useCallback((dates: DateRange) => {
    const [start, end] = dates;
    setLocalStartDate(start);
    setLocalEndDate(end);
    if (start && end) {
      // handle date change
      dispatch(setStartDate(DateHelper.dateToTimeStamp(start)));
      dispatch(setEndDate(DateHelper.dateToTimeStamp(end)));
    } else if (!start && !end) {
      // handle date clear
      dispatch(setStartDate(''));
      dispatch(setEndDate(''));
    }
  }, [dispatch]);

  return (
    <div className="lis-uploads__filter">
      <div className="row">
        <div className="col-md-2">
          <ComboBox items={statusComboBoxItems.current} multiSelect placeholderText="Status" value={filter.statuses} onChange={updateStatus} />
        </div>
        <div className="col-md-2">
          <ComboBox items={organizationComboBoxItems.current} multiSelect placeholderText="Ziekenhuis" value={filter.organizations} onChange={updateOrganization} />
        </div>
        <div className="col-md-2">
          <DatePicker
            placeholderText="Datum"
            selected={localStartDate}
            startDate={localStartDate}
            endDate={localEndDate}
            onChange={handleDateChange}
            selectsRange
            clearable
            onClear={() => handleDateChange([null, null])}
          />
        </div>
        <div className="col-md-2">
          <SearchField placeholder="Doorzoek de lijst" value={localQuery} onChange={(e) => updateQuery(e.target.value)} />
        </div>
        <div className="col-md-2">

        </div>
      </div>
    </div>
  );
}
