import { WorkflowDefinition } from '@support/components/workflowView/models';

const workflowDefinitionUtrecht: WorkflowDefinition = {
  className: 'workflow workflow--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Jaar',
    key: 'year',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Kwartaal',
    key: 'quarter',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Maand',
    key: 'month',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Datum',
    key: 'date',
    expandable: true,
    className: 'workflow__col--w10'
  }, {
    label: 'Z52',
    key: 'z52',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z53',
    key: 'z53',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z54',
    key: 'z54',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z55',
    key: 'z55',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Totaal',
    key: 'ut',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Toedracht',
    key: 'cause',
    className: 'workflow__col--ellipsis workflow__col--w25',
  }]
};

export default workflowDefinitionUtrecht;