import { ReactNode } from 'react';
import './SubLayout.scss';

export type Props = {
  children: ReactNode[],
  className?: string
}

export default function SubLayout3Cols({ className = '', children }: Props) {
  return (
    <div className={`container ${className}`}>
      <div className="row">
        <div className="col-md-4">
          {children[0]}
        </div>
        <div className="col-md-4">
          {children[1]}
        </div>
        <div className="col-md-4">
          {children[2]}
        </div>
      </div>
    </div>
  )
}