import { useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import InputWrapper from '@support/components/inputWrapper'
import DatePickerReact, { ReactDatePickerProps } from 'react-datepicker';
import nl from 'date-fns/locale/nl';

import './index.scss';

const parseYear = (s: string): number => {
  let y: number = parseInt(s);
  if (y < 1902) y = 1902;
  if (y > 2037) y = 2037;
  return y;
}

const parseMonth = (s: string): number => {
  let y: number = parseInt(s) - 1;
  if (y < 0) y = 0;
  if (y > 11) y = 11;
  return y;
}

const parseDay = (s: string): number => {
  let y: number = parseInt(s);
  if (y < 1) y = 1;
  if (y > 31) y = 31;
  return y;
}

const dateStringToDate = (d: string | null): (Date | null) => {
  if (d) {
    const parts = d.split('-');
    return new Date(
      parseYear(parts[2]),
      parseMonth(parts[1]),
      parseDay(parts[0])
    )
  }
  return null;
}

export type DateRange = [Date | null, Date | null];

interface Props extends ReactDatePickerProps<never, true> {
  name?: string | undefined,
  selected?: Date | null | undefined,
  startDate?: Date | null | undefined,
  endDate?: Date | null | undefined,
  className?: string,
  clearable?: boolean,
  onClear?: () => void,
  error?: FieldError
}

export default function DatePicker({ onClear, name, selected, startDate, endDate, className = '', clearable = false, error, ...rest }: Props) {

  const [localStartDate, setLocalStartDate] = useState<Date | null | undefined>(startDate);
  const [localEndDate, setLocalEndDate] = useState<Date | null | undefined>(endDate);

  useEffect(() => {
    setLocalStartDate(startDate);
  }, [startDate]);

  useEffect(() => {
    setLocalEndDate(endDate);
  }, [endDate]);

  // the default DatePickerReact range-select (selectsRange) does not support editing
  // of the text field. this workaround was created to add this behaviour
  const handleOnBlur = useCallback((value: string) => {
    if (rest.selectsRange === true && value) {
      const dates = value.split(' - ');
      if (dates.length === 2) {
        const start = dateStringToDate(dates[0]);
        const end = dateStringToDate(dates[1]);
        if (start && start.toString() !== 'Invalid Date' && end && end.toString() !== 'Invalid Date') {
          setLocalStartDate(start);
          setLocalEndDate(end);
          if (rest.onChange !== undefined) {
            rest.onChange([start, end], undefined);
          }
        }
      }
    }
  }, [rest]);

  return (
    <InputWrapper error={error} className={`date-picker ${className} ${clearable ? 'date-picker__clearable' : ''}`}>
      <DatePickerReact onBlur={(event: { target: { value: string; }; }) => handleOnBlur(event.target.value)} preventOpenOnFocus={true} enableTabLoop={false} name={name} selected={selected} startDate={localStartDate} endDate={localEndDate} {...rest} locale={nl} dateFormat="dd-MM-yyyy" />
      <button tabIndex={-1} className="date-picker__search">Search</button>
      {clearable && selected && (
        <button tabIndex={-1} className="date-picker__clear" onClick={() => { if (onClear) onClear() }}>X</button>
      )}
    </InputWrapper>
  );
}