import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import { useAppSelector } from '@src/app/store/hooks';

type Props = {
  readOnly?: boolean,
}

export default function Record({ readOnly = false }: Props) {
  const {
    record,
  } = useAppSelector(state => state.moveRecord)

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Record</legend>
      <FormRow fieldId="status" isActive={true}>
        <span>Status</span>
        <span>{record?.status}</span>
      </FormRow>
      <FormRow fieldId="organization" isActive={true}>
        <span>Organisatie</span>
        <span>{record?.bronId}</span>
      </FormRow>
      <FormRow fieldId="volgnummer" isActive={true}>
        <span>Volgnummer</span>
        <span>{record?.volgnummer}</span>
      </FormRow>
      {record?.type === 'RAV' ? (
        <FormRow fieldId="71" isActive={true}>
          <span>Datum inzet</span>
          <span>{record?.[71]}</span>
        </FormRow>
      ) : (
        <FormRow fieldId="70" isActive={true}>
          <span>Datum SEH</span>
          <span>{record?.[70]}</span>
        </FormRow>
      )}
      
    </FormFieldset>
  )
}