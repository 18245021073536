import { FieldError } from 'react-hook-form/dist/types';
import { useFormContext } from 'react-hook-form';

export interface Props {
  name: string,
  config?: object
  className?: string
  error?: FieldError
}

export default function InputCheckbox ({ name, className, error, config }: Props) {
  const classNames = [
    className,
    ...(error ? ['error'] : [])
  ];

  const methods = useFormContext()
  return (
    <>
      <div className={classNames.join(' ')}>
        <input type="checkbox" {...methods.register(name, config)} />
      </div>
      {error && error.message && (
        <span className="d-none" role="alert">
          {error.message}
        </span>
      )}
    </>
  );
}