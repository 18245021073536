import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import lisWorkflowDateAPI from '@features/lisWorkflowDate/api/api';
import { LisWorkflowDateFilter } from '@features/lisWorkflowDate/models/models'

// support
import { WorkflowItemObj } from '@support/components/workflowView/models';
import { updateFilter } from '@support/components/workflowView/helper';
import KeyValue from '@support/models/keyValue'

export interface LisWorkflowDateState {
  status: 'idle' | 'loading' | 'failed',
  items: WorkflowItemObj[],
  path: KeyValue,
  filter: LisWorkflowDateFilter
}

const initialState: LisWorkflowDateState = {
  status: 'idle',
  items: [],
  path: {},
  filter: {
    status: '',
    month: '',
    date: '',
    startDate: '',
    endDate: '',
    query: ''
  }
};

export const fetchLisWorkflowDateAsync = createAsyncThunk<WorkflowItemObj[], void, { state: RootState }>(
  'lisWorkflowDate/fetchLisWorkflowDateAsync',
  async (_, thunkAPI) => {

    const filter = thunkAPI.getState().lisWorkflowDate.filter;
    const params = new URLSearchParams();
    if (filter.status) params.append('status', filter.status);
    if (filter.month) params.append('month', filter.month);
    if (filter.date) params.append('date', filter.date);
    if (filter.startDate) params.append('startDate', filter.startDate);
    if (filter.endDate) params.append('endDate', filter.endDate);
    if (filter.query) params.append('query', filter.query);

    try {
      return await lisWorkflowDateAPI.workflow(params);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const lisWorkflowDateSlice = createSlice({
  name: 'lisWorkflowDate',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPath: (state, action: PayloadAction<{ key: string, path: KeyValue }>) => {
      const levels: string[] = ['status', 'month', 'date'];
      updateFilter(action.payload.key, action.payload.path, state.filter, levels)
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.filter.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.filter.endDate = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      if (state.filter.query !== action.payload) {
        state.filter.query = action.payload;
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // --------------------------------------------------------------------------
      // workflow
      .addCase(fetchLisWorkflowDateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLisWorkflowDateAsync.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchLisWorkflowDateAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setPath, setStartDate, setEndDate, setQuery } = lisWorkflowDateSlice.actions;

export default lisWorkflowDateSlice.reducer;
