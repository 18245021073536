import React, { memo, useCallback } from 'react';
import { ListViewItemData, ListViewEventType, ListViewEvent, ListViewDefinition } from './models';

export type Props = {
  definition: ListViewDefinition,
  data: ListViewItemData,
  onListViewEvent?: (event: ListViewEvent) => void
}

function ListViewItem({ definition, data, onListViewEvent }: Props) {

  // console.log('render listview item')

  /*
   * onCheckboxChange
   * send checkbox change event to parent so the model can be updated
   *
   */
  const onCheckboxChange = useCallback((item: ListViewItemData) => {
    if (onListViewEvent) {
      const event: ListViewEvent = {
        event: !item.checked ? ListViewEventType.CHECKBOX_CHECKED : ListViewEventType.CHECKBOX_UNCHECKED,
        itemId: item.id
      }
      onListViewEvent(event);
    }
  }, [onListViewEvent]);

  /*
   * onClick
   * send click event event to parent so the model can be updated
   *
   */
  const onClick = useCallback((e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: ListViewItemData) => {
    const element = e.target as HTMLElement;
    if (element.tagName === 'INPUT' || element.className.indexOf('listview__checkbox-col') !== -1) return; // to not triggrt click when checkbox is clicked
    if (onListViewEvent) {
      const event: ListViewEvent = {
        event: ListViewEventType.CLICK,
        itemId: item.id
      }
      onListViewEvent(event);
    }
  }, [onListViewEvent]);

  return (
    <tr className={data.checked ? 'listview__row--checked' : ''} onClick={(e) => onClick(e, data)}>
      {definition.hasCheckBox &&
        <td className="listview__checkbox-col"><input type="checkbox" checked={data.checked} onChange={() => onCheckboxChange(data)} /></td>
      }
      {Object.keys(data.data).map((i: string, idx: number) => (
        <td key={i} className={definition.columns[idx].className}><div>{data.data[i]}</div></td>
      ))}
      {definition.hasLink &&
        <td className="listview__link-col">&nbsp;</td>
      }
    </tr>
  );
}

export default memo(ListViewItem);