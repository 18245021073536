import { ReactNode, /* isValidElement, cloneElement, Children, */ useCallback } from 'react';
import ButtonDirection from '@support/components/buttonDirection';

// support
import useKeyPresses, { ignoreKeyPress } from '@support/hooks/useKeyPresses';

import './index.scss';

export type Props = {
  children?: ReactNode,
  className?: string,
  hasPaging?: boolean,
  buttonPrevCallback?: () => void,
  buttonNextCallback?: () => void,
  buttonPrevEnabled?: boolean,
  buttonNextEnabled?: boolean,
  active: boolean
}

export default function ActionBar({ children, className = '', active = false, buttonPrevEnabled = true, buttonNextEnabled = true, buttonPrevCallback, buttonNextCallback, hasPaging }: Props) {

  const handleButtonPrev = useCallback(() => {
    if (buttonPrevCallback && hasPaging) buttonPrevCallback();
  }, [buttonPrevCallback, hasPaging])

  const handleButtonNext = useCallback(() => {
    if (buttonNextCallback && hasPaging) buttonNextCallback();
  }, [buttonNextCallback, hasPaging])

  /**
   * Handle key presses
   */
  useKeyPresses('ArrowLeft', useCallback((e) => {
    if (!ignoreKeyPress()) {
      const el = document.querySelector('.action-bar .button-direction--left') as HTMLElement;
      if (el) el.click();
    }
  }, []));

  useKeyPresses('ArrowRight', useCallback((e) => {
    if (!ignoreKeyPress()) {
      const el = document.querySelector('.action-bar .button-direction--right') as HTMLElement;
      if (el) el.click();
    }
  }, []));

  /* used for bulk actions, activates all buttons based on 'active' prop
  const renderButtons = (c: ReactNode) => {
    if (c === undefined) return c;
    return Children.map(c, x => {
      if (isValidElement(x)) {
        return cloneElement(x, { 'disabled': !active } as React.Attributes);
      }
    })
  }
  */

  return (
    <div className={`action-bar ${className}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="action-bar__container">
              {hasPaging === true && (
                <ButtonDirection disabled={!buttonPrevEnabled} direction="left" onClick={(e) => { handleButtonPrev(); e.preventDefault() }}>Vorige</ButtonDirection>
              )}
              {children}
              {hasPaging === true && (
                <ButtonDirection disabled={!buttonNextEnabled} direction="right" onClick={(e) => { handleButtonNext(); e.preventDefault() }}>Volgende</ButtonDirection>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}