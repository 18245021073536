import React, { forwardRef, ComponentProps } from 'react';
import InputWrapper from '@support/components/inputWrapper'
import './index.scss';

interface Props extends ComponentProps<'input'> {
  className?: string
}

export default forwardRef<HTMLInputElement, Props>(({ className = '', ...rest }, ref) => {
  return (
    <InputWrapper className={`search-field ${className}`}>
      <input type="text" ref={ref} {...rest}  />
      <button>Search</button>
    </InputWrapper>
  );
})