import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function RoadwayFactors({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();
  
  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_20 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 20, description: 'Wegsituatie', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_21 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 21, description: 'Locatie op de weg', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_33 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 33, description: 'Manoeuvre', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_65 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 65, description: 'File', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Wegfactoren</legend>
      <FormRow fieldId="20" isActive={visibility[20]} fieldRules={field_20.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_20.current.description}</span>
        <Controller
          control={methods.control}
          name="20"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_20.current.options} valueRules={field_20.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="21" isActive={visibility[21]} fieldRules={field_21.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_21.current.description}</span>
        <Controller
          control={methods.control}
          name="21"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_21.current.options} valueRules={field_21.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="33" isActive={visibility[33]} fieldRules={field_33.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_33.current.description}</span>
        <Controller
          control={methods.control}
          name="33"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_33.current.options} valueRules={field_33.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="65" isActive={visibility[65]} fieldRules={field_65.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_65.current.description}</span>
        <Controller
          control={methods.control}
          name="65"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_65.current.options} valueRules={field_65.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}