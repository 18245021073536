import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import moveRecordDataLineageAPI from '@features/moveRecordDataLineage/api/api';
import { MoveRecordDataLineageResponse } from '@features/moveRecordDataLineage/models/models';

export interface MoveRecordDataLineageState {
    status: 'idle' | 'loading' | 'failed';
    listData: MoveRecordDataLineageResponse | undefined,
}

const initialState: MoveRecordDataLineageState = {
    status: 'idle',
    listData: undefined,
};

export const fetchMoveRecordDataLineageAsync = createAsyncThunk<MoveRecordDataLineageResponse, { id: string }, { state: RootState }>(
    'moveRecordDataLineage/fetchMoveRecordDataLineageAsync',
    async (payload, thunkAPI,) => {
        const params = new URLSearchParams();

        try {
            return await moveRecordDataLineageAPI.list(payload.id, params);
        } catch (error: any) {
            if (!error.response) throw error
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

export const moveRecordDataLineageSlice = createSlice({
    name: 'moveRecordDataLineage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMoveRecordDataLineageAsync.pending, (state) => {
                state.listData = [];
                state.status = 'loading';
            })
            .addCase(fetchMoveRecordDataLineageAsync.fulfilled, (state, action) => {
                state.listData = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchMoveRecordDataLineageAsync.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

export default moveRecordDataLineageSlice.reducer;
