import React, { useEffect, useState } from 'react';
import { ListViewItemData, ListViewEvent, ListViewDefinition, ListViewSort } from './models';
import ListViewHeader from './ListViewHeader';
import ListViewItem from './ListViewItem';

import './index.scss'

export type Props = {
  definition: ListViewDefinition,
  data: ListViewItemData[],
  onListViewEvent?: (event: ListViewEvent) => void,
  loading?: boolean,
  sortKey?: ListViewSort
}

export default function ListView({ definition, data, onListViewEvent, sortKey }: Props) {

  // local search query value
  const [allItemsChecked, setAllItemsChecked,] = useState<boolean>(false);

  useEffect(() => {

    // data property has changed
    // check if all items are checked
    let allItemsAreChecked = data.length ? true : false;
    data.forEach(i => {
      if (i.checked === false) {
        allItemsAreChecked = false;
      }
    })

    // reset ListViewHeader checked status after data reload
    setAllItemsChecked(allItemsAreChecked);

  }, [data]);

  return (
    <div className={`listview ${definition.className || ''} ${definition.hasLink ? 'listview--clickable' : ''}`}>
      <table>
        <ListViewHeader sortKey={sortKey} definition={definition} onListViewEvent={onListViewEvent} allItemsChecked={allItemsChecked} />
        <tbody>
          {data.map((item: ListViewItemData) => (
            <ListViewItem key={item.id} definition={definition} data={item} onListViewEvent={onListViewEvent} />
          ))}
        </tbody>
      </table>
    </div>
  );
}