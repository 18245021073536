import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function Attributions({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);
  
  const field_78 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 78, description: 'Toedracht', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_30 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 30, description: 'Uitglijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_31 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 31, description: 'Stuurfout', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_32 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 32, description: 'Uit de bocht', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_34 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 34, description: 'Schrikken', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_35 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 35, description: 'In slaap vallen', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_36 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 36, description: 'Niet opletten', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_37 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 37, description: 'Elkaar niet zien', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_38 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 38, description: 'Geen voorrang verlenen', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_39 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 39, description: 'Afsnijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_40 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 40, description: 'Door rood rijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_41 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 41, description: 'Te hard rijden', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_42 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 42, description: 'Uitwijken', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_43 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 43, description: 'Remmen', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_45 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 45, description: 'Overig', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_46 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 46, description: 'Sturen in elkaar', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_47 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 47, description: 'Uit balans', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_48 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 48, description: 'Trapper', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_49 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 49, description: 'Beknelling lichaam', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_50 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 50, description: 'Beknelling anders ', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_52 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 52, description: 'Toedracht tegenpartij', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_51 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 51, description: 'Toedracht obstakel', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  
  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Toedrachten</legend>
      <FormRow fieldId="78" isActive={visibility[78]} fieldRules={field_78.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_78.current.description}</span>
        <Controller
          control={methods.control}
          name="78"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_78.current.options} valueRules={field_78.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="30" isActive={visibility[30]} fieldRules={field_30.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_30.current.description}</span>
        <Controller
          control={methods.control}
          name="30"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_30.current.options} valueRules={field_30.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="31" isActive={visibility[31]} fieldRules={field_31.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_31.current.description}</span>
        <Controller
          control={methods.control}
          name="31"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_31.current.options} valueRules={field_31.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="32" isActive={visibility[32]} fieldRules={field_32.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_32.current.description}</span>
        <Controller
          control={methods.control}
          name="32"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_32.current.options} valueRules={field_32.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="34" isActive={visibility[34]} fieldRules={field_34.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_34.current.description}</span>
        <Controller
          control={methods.control}
          name="34"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_34.current.options} valueRules={field_34.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="35" isActive={visibility[35]} fieldRules={field_35.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_35.current.description}</span>
        <Controller
          control={methods.control}
          name="35"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_35.current.options} valueRules={field_35.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="36" isActive={visibility[36]} fieldRules={field_36.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_36.current.description}</span>
        <Controller
          control={methods.control}
          name="36"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_36.current.options} valueRules={field_36.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="37" isActive={visibility[37]} fieldRules={field_37.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_37.current.description}</span>
        <Controller
          control={methods.control}
          name="37"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_37.current.options} valueRules={field_37.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="38" isActive={visibility[38]} fieldRules={field_38.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_38.current.description}</span>
        <Controller
          control={methods.control}
          name="38"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_38.current.options} valueRules={field_38.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="39" isActive={visibility[39]} fieldRules={field_38.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_39.current.description}</span>
        <Controller
          control={methods.control}
          name="39"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_39.current.options} valueRules={field_39.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="40" isActive={visibility[40]} fieldRules={field_38.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_40.current.description}</span>
        <Controller
          control={methods.control}
          name="40"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_40.current.options} valueRules={field_40.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="41" isActive={visibility[41]} fieldRules={field_38.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_41.current.description}</span>
        <Controller
          control={methods.control}
          name="41"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_41.current.options} valueRules={field_41.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="42" isActive={visibility[42]} fieldRules={field_42.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_42.current.description}</span>
        <Controller
          control={methods.control}
          name="42"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_42.current.options} valueRules={field_42.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="43" isActive={visibility[43]} fieldRules={field_43.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_43.current.description}</span>
        <Controller
          control={methods.control}
          name="43"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_43.current.options} valueRules={field_43.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="45" isActive={visibility[45]} fieldRules={field_45.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_45.current.description}</span>
        <Controller
          control={methods.control}
          name="45"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_45.current.options} valueRules={field_45.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="46" isActive={visibility[46]} fieldRules={field_46.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_46.current.description}</span>
        <Controller
          control={methods.control}
          name="46"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_46.current.options} valueRules={field_46.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="47" isActive={visibility[47]} fieldRules={field_47.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_47.current.description}</span>
        <Controller
          control={methods.control}
          name="47"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_47.current.options} valueRules={field_47.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="48" isActive={visibility[48]} fieldRules={field_48.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_48.current.description}</span>
        <Controller
          control={methods.control}
          name="48"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_48.current.options} valueRules={field_48.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="49" isActive={visibility[49]} fieldRules={field_49.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_49.current.description}</span>
        <Controller
          control={methods.control}
          name="49"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_49.current.options} valueRules={field_49.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="50" isActive={visibility[50]} fieldRules={field_50.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_50.current.description}</span>
        <Controller
          control={methods.control}
          name="50"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_50.current.options} valueRules={field_50.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="52" isActive={visibility[52]} fieldRules={field_52.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_52.current.description}</span>
        <Controller
          control={methods.control}
          name="52"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_52.current.options} valueRules={field_52.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="51" isActive={visibility[51]} fieldRules={field_51.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_51.current.description}</span>
        <Controller
          control={methods.control}
          name="51"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_51.current.options} valueRules={field_51.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}