import { useCallback, useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import TextareaField from '@src/support/components/textareaField';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule, UniquePairsInfo } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';
import { useFormValidators } from '@src/support/hooks/useFormValidators';

type Props = {
  readOnly?: boolean,
}

// setup unique pairs for 'letstel'
const uniquePairsLetsel: UniquePairsInfo = {
  pairs: [
    { field1: '24', field2: '25' },
    { field1: '26', field2: '27' },
    { field1: '28', field2: '29' }
  ]
}

export default function Diagnosis({ readOnly = false }: Props) {

  const methods = useFormContext();
  const setValue = methods.setValue;

  const { isSet, uniquePairs, conditionalRequired, shiftEmptyPairs, triggerRelatedPairs } = useFormValidators();
  const { visibility, updateHandler } = useFormVisibility();
  const { cache } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  // shift empty pairs when needed
  const uniquePairsLetselChanged = useCallback((value: any, field?: string) => {
    if (field) {
      if (value === '') {
        shiftEmptyPairs(uniquePairsLetsel, field);
      } else {
        setValue(field, value, { shouldDirty: true });
        triggerRelatedPairs(uniquePairsLetsel);
      }
    }
  }, [setValue, shiftEmptyPairs, triggerRelatedPairs]);

  const field_24 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 24,
    description: 'Letsel type 1',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_25 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 25,
    description: 'Lichaamsdeel letsel 1',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_26 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 26,
    description: 'Letsel type 2',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_27 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 27,
    description: 'Lichaamsdeel letsel 2',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_28 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 28,
    description: 'Letsel type 3',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_29 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 29,
    description: 'Lichaamsdeel letsel 3',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  return (

    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Diagnose</legend>
      <FormRow fieldId="23" isActive={true} classNames={{ leftColumn: 'col-12', rightColumn: 'd-none' }}>
        <Controller
          control={methods.control}
          name="23"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextareaField name={name} value={value || ''} error={error} onChange={onChange} />
          )}
        />
        <></>
      </FormRow>

      <FormRow fieldId="24" isActive={visibility[24]} fieldRules={field_24.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_24.current.description}</span>
        <Controller
          control={methods.control}
          name="24"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '24', value),
              required: (value) => isSet(value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_24.current.options} valueRules={field_24.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="25" isActive={visibility[25]} fieldRules={field_25.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_25.current.description}</span>
        <Controller
          control={methods.control}
          name="25"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '25', value),
              required: (value) => isSet(value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error}
              onChange={uniquePairsLetselChanged} options={field_25.current.options} valueRules={field_25.current.valueRules} />
          )}
        />;
      </FormRow>


      <FormRow fieldId="26" isActive={visibility[26]} fieldRules={field_26.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_26.current.description}</span>
        <Controller
          control={methods.control}
          name="26"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '26', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniquePairsLetselChanged} options={field_26.current.options} valueRules={field_26.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="27" isActive={visibility[27]} fieldRules={field_27.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_27.current.description}</span>
        <Controller
          control={methods.control}
          name="27"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '27', value),
              required: (value) => conditionalRequired([{ field: '26', value: 'any' }], value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniquePairsLetselChanged} options={field_27.current.options} valueRules={field_27.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="28" isActive={visibility[28]} fieldRules={field_28.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_28.current.description}</span>
        <Controller
          control={methods.control}
          name="28"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '28', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniquePairsLetselChanged} options={field_28.current.options} valueRules={field_28.current.valueRules} />
          )}
        />;
      </FormRow>


      <FormRow fieldId="29" isActive={visibility[29]} fieldRules={field_29.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_29.current.description}</span>
        <Controller
          control={methods.control}
          name="29"
          rules={{
            validate: {
              uniquePairsLetsel: (value) => uniquePairs(uniquePairsLetsel, '29', value),
              required: (value) => conditionalRequired([{ field: '28', value: 'any' }], value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniquePairsLetselChanged} options={field_29.current.options} valueRules={field_29.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}