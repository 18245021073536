import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { useParams } from 'react-router-dom';

// features
import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';

// support
import ListView from '@support/components/listView';
import ListViewItemDataHelper from '@support/components/listView/listViewDataHelper';
import { ListViewItemData } from '@support/components/listView/models';
import Throbber from '@support/components/throbber';

// store
import {
    fetchLisRecordDataLineageAsync,
} from '@src/features/lisRecordDataLineage/store/slice'

import listDefinition from './listDefinition';

type Params = {
    id: string
}

export default function LisRecordDataLineage() {
    const { id } = useParams() as Params;

    const [listViewData, setListViewData] = useState<ListViewItemData[]>([]);
    const dispatch = useAppDispatch();

    const {
        listData,
        status,
    } = useAppSelector(state => state.lisRecordDataLineage);

    /**
     * Load the data lineage items for a specific record.
     */
    const loadItems = useCallback(() => {
        dispatch(fetchLisRecordDataLineageAsync({ id })).unwrap().catch(() => {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Fout bij ophalen van de lijst.',
                autoDismissMilliseconds: 3000
            }));
        })
    }, [dispatch, id]);

    useEffect(() => {
        loadItems();
    }, [loadItems])

    /**
     * Transform raw data for use in listview as defined in ListDefinition
     */
    useEffect(() => {
        if (listData) {
            setListViewData(ListViewItemDataHelper.transformData(listData, listDefinition));
            window.scrollTo({
                top: 0
            });
        }
    }, [listData]);

    return (
        <>
            <Throbber visible={status === 'loading'} />
            {
                listViewData.length === 0 ? (
                    <>
                        {status !== 'loading' &&
                            <div className="listview__no-result">
                                <div>Geen resultaten</div>
                            </div>
                        }
                    </>
                ) : (
                    <>
                        <ListView definition={listDefinition} data={listViewData} />
                    </>
                )
            }
        </>
    )
}

