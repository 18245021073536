import { WorkflowDefinition } from '@support/components/workflowView/models';

const workflowDefinition: WorkflowDefinition = {
  className: 'workflow workflow--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Ziekenhuis',
    key: 'hospitalId',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Maand',
    key: 'month',
    expandable: true,
    className: 'workflow__col--w5'
  }, {
    label: 'Niet letsel',
    key: 'notInjury',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Letsel',
    key: 'injury',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Verkeer',
    key: 'traffic',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Bedrijf',
    key: 'work',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Geweld',
    key: 'violence',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Zelfmutilatie',
    key: 'selfMutilation',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Sport',
    key: 'sport',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Prive',
    key: 'private',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Onbekend',
    key: 'unkown',
    clickable: true,
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Totaal',
    key: 'total',
    showTotal: true,
    className: 'workflow__col--align-r'
  }, {
    label: 'Toedracht',
    key: 'cause',
    className: 'workflow__col--ellipsis workflow__col--w25',
  }]
};

export default workflowDefinition;