import { useState, useEffect, RefObject } from 'react';

const useHasFocus = (ref: RefObject<any>) => {

  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {

    const listener = (event: FocusEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (ref.current && ref.current.contains(document.activeElement)) {
        setHasFocus(true);
        return;
      }
      setHasFocus(false);
    };

    document.addEventListener('focusin', listener);
    document.addEventListener('focusout', listener);
  },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref]
  );

  return hasFocus
}

export default useHasFocus