import { useCallback, useState } from 'react';

// support
import Button from '@src/support/components/button';
import DatePicker, { DateRange } from '@src/support/components/datePicker';

import './index.scss';

interface Props {
    onSubmit?: (startDate: Date, endDate: Date) => void,
}

export default function ExportControls({ onSubmit }: Props) {
    const [localStartDate, setLocalStartDate] = useState<Date | null>();
    const [localEndDate, setLocalEndDate] = useState<Date | null>();
    const hasLocalDateRange = localStartDate && localEndDate;

    const handleDateChange = useCallback((dates: DateRange) => {
        const [start, end] = dates;
        setLocalStartDate(start);
        setLocalEndDate(end);
    }, []);

    const submit = useCallback(() => {
        if (!hasLocalDateRange || !onSubmit) {
            return;
        }

        onSubmit(localStartDate, localEndDate);
    }, [hasLocalDateRange, localStartDate, localEndDate, onSubmit])

    return (
        <div className="export-controls">
            <DatePicker
                placeholderText="Datum"
                selected={localStartDate}
                startDate={localStartDate}
                endDate={localEndDate}
                onChange={handleDateChange}
                selectsRange
                clearable
                onClear={() => handleDateChange([null, null])}
            />
            <Button
                className="export-controls__button button--primary"
                disabled={!hasLocalDateRange}
                onClick={submit}
            >
                <span className="export-controls__icon" />
            </Button>
        </div>
    );
}
