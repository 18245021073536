// app
import SubLayout1Col from '@layouts/subLayouts/SubLayout1Col';
import SectionDefault from '@layouts/sections/SectionDefault';

// features
import Workflow from '@features/moveWorkflow';

// support
import Breadcrumbs from '@support/components/breadcrumbs';

export default function Registrations() {
  return (
    <>
      <SectionDefault>
        <SubLayout1Col>
          <Breadcrumbs />
        </SubLayout1Col>
        <SubLayout1Col>
          <h1>Workflow</h1>
          <Workflow />
        </SubLayout1Col>
      </SectionDefault>
    </>
  );
}
