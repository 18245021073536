import { ReactNode } from 'react';
import './SubLayout.scss';

export type Props = {
  children?: ReactNode,
  className?: string
}

export default function SubLayout1Col({ className = '', children }: Props) {
  return (
    <div className={`container ${className}`}>
      <div className="row">
        <div className="col-md-12">
          {children}
        </div>
      </div>
    </div>
  )
}