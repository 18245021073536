import { FieldError } from 'react-hook-form/dist/types';
import InputWrapper from '@src/support/components/inputWrapper';
import { useFormContext } from 'react-hook-form';

export interface Props {
  name: string,
  config?: object,
  className?: string,
  error?: FieldError,
  maxLength?: number,
}

export default function InputField ({ name, className, error, config, maxLength }: Props) {
  const methods = useFormContext()
  return (
    <>
      <InputWrapper error={error} className={className}>
        <input type="text" {...methods.register(name, config)} data-lpignore="true" maxLength={maxLength} />
      </InputWrapper>
    </>
  );
}