import { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import ComboBox, { ComboBoxItem } from '@support/components/comboBox'
import KeyValue from '@src/support/models/keyValue';
import { FormValue } from '@src/support/components/form/models';
import { Props } from './models';

export default function ComboBoxDynamic({ name, className, options, error, valueRules, value, hasResetOption, onChange, onValueUpdated }: Props) {

  // React form hook
  const methods = useFormContext();
  const watch = methods.watch;
  const getValues = methods.getValues;
  const resetField = methods.resetField;

  const [watchers, setWatchers] = useState<number[]>([]);

  // Select options state
  const [selectOptions, setSelectOptions] = useState<FormValue[]>([])
  const [comboBoxItems, setComboBoxItems] = useState<ComboBoxItem[]>([])

  const onChangeCallback = useCallback((e: any) => {
    if (onChange && e[0] !== undefined) {
      onChange(e[0], name);
    }
  }, [onChange, name]);

  useEffect(() => {
    if (onValueUpdated) onValueUpdated(value);
  }, [onValueUpdated, value]);

  useEffect(() => {
    let comboBoxItems: ComboBoxItem[] = selectOptions.map(e => {
      const id: string = e.id.toString() || '';
      // %SYN% marks te point where the synonyms start, these are not displayed in the label
      // but are searchable
      const label = e.synonyms ? `${e.label}%SYN%${e.synonyms}` : e.label;
      return {
        label: label,
        value: id
      }
    })

    if (hasResetOption) {
      const emptyOption: ComboBoxItem = {
        label: '-- Selecteer --',
        value: ''
      }

      comboBoxItems = [
        ...[emptyOption],
        ...comboBoxItems
      ]
    }

    setComboBoxItems(comboBoxItems);


  }, [name, selectOptions, hasResetOption]);

  /** 
   * getOptions()
   *  Retrieves the select options when optionsSource property is provided...
   * 
   * @param values {string}
   * @param srcListId {number}
   */
  const getOptions = useCallback((values: KeyValue) => {
    if (!valueRules) return options ?? [];

    const filteredOptions = valueRules.filter(condition => {
      const value = Number(values[condition.masterFieldId]) ?? null;
      return value && value === condition.masterValueId;
    }).map((condition) => condition.valueId);

    if (!filteredOptions.length) return options ?? [];

    return options.filter(option => filteredOptions.includes(option.id));
  }, [options, valueRules])

  /** 
   * useEffect()
   *  Uses watch from React Form Hook to watch any changes on fields. When a field 
   *  changes it will reset the select options if there are any matches...
   */
  useEffect(() => {

    const fieldName = name;
    const subscription = watch((values, { name }) => {
      const srcFieldId = name ? Number(name) : null

      if (!srcFieldId || !watchers.includes(srcFieldId)) return;

      const selectOptions: FormValue[] = getOptions(values);
      if (!selectOptions.length) return;

      setSelectOptions(selectOptions)

      // reset the value when the current value is not present in the options
      const selectOption = selectOptions.map(item => item.id.toString());
      if (fieldName && values[fieldName] && selectOption.includes(values[fieldName]) === false) {
        resetField(fieldName, { defaultValue: null });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, watchers, getOptions, resetField, name]);

  /** 
   * useEffect()
   *  Initial setup of select input component. 
   *  Retrieves and set the initial select options.
   */
  useEffect(() => {

    const values = getValues();
    const watchers = Array.from(new Set(valueRules?.map(condition => condition.masterFieldId)));
    const selectOptions: FormValue[] = getOptions(values);

    if (!selectOptions.length) return;

    setWatchers(watchers)
    setSelectOptions(selectOptions)

  }, [name, valueRules, getValues, getOptions])

  return (
    <ComboBox value={[value ? String(value) : '']} className={error ? 'error' : ''} items={comboBoxItems} onChange={onChangeCallback} />
  );
}
