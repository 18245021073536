import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// layouts
import SubLayout3Cols from '@layouts/subLayouts/SubLayout3Cols';
import SectionDefault from '@layouts/sections/SectionDefault';

// features
import AccidentFacts from '@features/lisRecord/components/AccidentFacts';
import Diagnosis from '@features/lisRecord/components/Diagnosis';
import General from '@features/lisRecord/components/General';
import InternalMessage from '@features/lisRecord/components/InternalMessage';
import Person from '@features/lisRecord/components/Person';
import PrivateAccident from '@features/lisRecord/components/PrivateAccident';
import Record from '@features/lisRecord/components/Record';
import Scenario from '@features/lisRecord/components/Scenario';
import SportAccident from '@features/lisRecord/components/SportAccident';
import TrafficAccident from '@features/lisRecord/components/TrafficAccident';
import Treatment from '@features/lisRecord/components/Treatment';
import Voilence from '@features/lisRecord/components/Voilence';
import WorkAccident from '@features/lisRecord/components/WorkAccident';
import { LisRecordValidationErrorResponse } from '@features/lisRecord/models/models';
import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';

// support
import ActionBar from '@support/components/actionBar';
import Button from '@support/components/button';
import Form from '@support/components/form';
import KeyValue from '@support/models/keyValue';
import Popup from '@support/components/popup';
import Throbber from '@support/components/throbber';
import TitleHeader, { Props as TitleHeaderProps } from '@support/components/titleHeader';
import { PopupObject, PopupType } from '@support/components/popup/models';
import { SubmitEventType } from '@support/components/form/models';
import { useBeforeUnload } from '@support/hooks/useBeforeUnload';
import { useBlocker } from '@support/hooks/useBlocker';

// store
import {
    setRecord,
    fetchLisRecordAsync,
    saveRecordAsync,
    rejectRecordAsync,
    approveRecordAsync,
    unlockCurrentRecordAsync,
    lockCurrentRecordAsync,
    discardDuplicateRecordAsync,
    stageDuplicateRecordAsync,
    STATUS_FIELD_ID, STATUS_DUPLICATE, STATUS_DECLINED, STATUS_ACCEPTED
} from '@features/lisRecord/store/slice';

import {
    removePrevNextRecordId
} from '@features/lisRegistration/store/slice';

type Params = {
    id: string
}

export default function LisRecord() {
    const routeParams = useParams() as Params;

    const [popupObj, setPopupObj] = useState<PopupObject | undefined>();
    const formIsDirty = useRef<boolean>(false);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
    const localRecord = useRef<KeyValue>({});
    const [reasonRejectError, setReasonRejectError] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        record,
        status,
    } = useAppSelector(state => state.lisRecord)

    const {
        recordIds
    } = useAppSelector(state => state.lisRegistration)

    const header: TitleHeaderProps = {
        title: routeParams.id,
        navigationButton: {
            title: 'Geschiedenis',
            url: `/lis/registration/${routeParams.id}/history`
        }
    }

    /**
     * unlock the current record and continue navigation
     * @context: NavigationContext
     */
    const unlockAndContinue = useCallback((context: any) => {
        formIsDirty.current = false;
        if (record?.lockedByOther === false) {
            dispatch(unlockCurrentRecordAsync()).unwrap().then(() => {
                if (context.retry) context.retry();
            }).catch(e => {
                dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij unlocken van het record.', autoDismissMilliseconds: 3000 }));
            })
        } else {
            if (context.retry) context.retry();
        }
    }, [dispatch, record?.lockedByOther]);

    useBeforeUnload();

    useBlocker((context: any) => {
        if (formIsDirty.current) {
            const popup: PopupObject = {
                type: PopupType.WARNING,
                title: 'Wijzigingen opslaan?',
                text: 'Wil je de wijzigingen aan deze record opslaan?',
                buttonConfirm: 'Opslaan',
                onConfirm: () => { // save record and unlock before leaving page
                    dispatch(saveRecordAsync(localRecord.current)).unwrap().then(() => {
                        dispatch(addNotification({ type: NotificationType.INFO, text: 'Record is opgeslagen', autoDismissMilliseconds: 3000 }));
                        unlockAndContinue(context);
                    }).catch((e) => {
                        dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij opslaan van het record.', autoDismissMilliseconds: 3000 }));
                    });
                },
                buttonCancel: 'Niet opslaan',
                onCancel: () => { // don't save record, unlock before leaving page
                    unlockAndContinue(context);
                },
            }
            setPopupObj(popup);
        } else { // form not dirty, so don't save, unlock before leaving page
            unlockAndContinue(context);
        }
    }, true);

    useEffect(() => {
        if (routeParams.id) {
            dispatch(fetchLisRecordAsync(routeParams.id)).unwrap().then((rec: any) => {

                localRecord.current = rec;
                formIsDirty.current = false;

                setDisableSaveButton(true);

                if (rec?.lockedByOther === false) {
                    dispatch(lockCurrentRecordAsync()).unwrap().catch((e) => {
                        dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij locken van het record.', autoDismissMilliseconds: 3000 }));
                    });
                }
                window.scrollTo({ top: 0 });
            }).catch((e) => {
                dispatch(addNotification({ type: NotificationType.WARNING, text: 'Fout bij ophalen van het record.', autoDismissMilliseconds: 3000 }));
            })
        }
    }, [dispatch, routeParams.id])

    /**
     * Handle dirty change
     */
    const handleDirty = useCallback(() => {
        formIsDirty.current = true;
        setDisableSaveButton(false);
    }, []);

    /**
     * Handle data change
     */
    const handleData = useCallback((data: object) => {
        localRecord.current = data;
    }, []);

    /**
     * Navigate to previous record
     */
    const navigateToPrevRecord = useCallback(() => {
        if (recordIds) {
            const recordIndex: number = recordIds.indexOf(record?.id);
            if (recordIndex !== -1 && recordIndex > 0) {
                const previousRecordId: string = recordIds[recordIndex - 1];
                navigate(`/lis/registration/${previousRecordId}`);
            } else {
                navigate('/lis/registration/');
            }
        }
    }, [navigate, record?.id, recordIds]);

    /**
     * Navigate to next record
     */
    const navigateToNextRecord = useCallback(() => {
        if (recordIds) {
            const recordIndex: number = recordIds.indexOf(record?.id);
            if (recordIndex !== -1 && recordIndex < recordIds.length - 1) {
                const nextRecordId: string = recordIds[recordIndex + 1];
                navigate(`/lis/registration/${nextRecordId}`);
            } else {
                navigate('/lis/registration/');
            }
        }
    }, [navigate, record?.id, recordIds]);

    /**
     * Save record
     * Just save the current state, validation is not triggered
     */
    const handleSaveRecord = useCallback(() => {

        setReasonRejectError(false);

        dispatch(saveRecordAsync(localRecord.current)).unwrap().then((e) => {
            formIsDirty.current = false;
            setDisableSaveButton(true);

            dispatch(setRecord(localRecord.current))
            dispatch(addNotification({
                type: NotificationType.INFO,
                text: 'De aanpassingen zijn opgeslagen',
                autoDismissMilliseconds: 3000
            }));
        }).catch((e) => {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Fout bij het opslaan van het record.',
                autoDismissMilliseconds: 3000
            }));

            const response = e?.error as LisRecordValidationErrorResponse;
            response.errors.forEach(error => {
                dispatch(addNotification({
                    type: NotificationType.WARNING,
                    text: error.message ?? error.code,
                    autoDismissMilliseconds: 3000
                }));
            })
        })
    }, [dispatch]);

    /**
     * Reject record
     */
    const handleRejectRecord = useCallback(() => {

        // show error when 'reden afkeur is empty'
        if (localRecord.current[2] === null) {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Vul alle verplichte velden.',
                autoDismissMilliseconds: 3000
            }));

            setReasonRejectError(true);
            return;
        }

        dispatch(rejectRecordAsync(localRecord.current)).unwrap().then((e) => {
            dispatch(addNotification({
                type: NotificationType.INFO,
                text: 'De aanpassingen zijn opgeslagen',
                autoDismissMilliseconds: 3000
            }));

            formIsDirty.current = false;
            setDisableSaveButton(true);
            dispatch(removePrevNextRecordId(localRecord.current.id));
            navigateToNextRecord();
        }).catch((e) => {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Fout bij het aanpassen van de status.',
                autoDismissMilliseconds: 3000
            }));
        })
    }, [dispatch, navigateToNextRecord]);

    /**
     * Discard duplicate record
     */
    const handleDiscardDuplicateRecord = useCallback(() => {
        dispatch(discardDuplicateRecordAsync(localRecord.current)).unwrap().then((e) => {
            dispatch(addNotification({
                type: NotificationType.INFO,
                text: 'De aanpassingen zijn opgeslagen',
                autoDismissMilliseconds: 3000
            }));
            formIsDirty.current = false;
            setDisableSaveButton(true);
            dispatch(removePrevNextRecordId(localRecord.current.id));
            navigateToNextRecord();
        }).catch((e) => {
            switch (e.status) {
                default:
                    dispatch(addNotification({
                        type: NotificationType.WARNING,
                        text: 'Fout bij het goedkeuren van het record.',
                        autoDismissMilliseconds: 3000
                    }));
                    break;
                case 403:
                    dispatch(addNotification({
                        type: NotificationType.WARNING,
                        text: 'Je bent niet gemachtigd om duplicaten te bewerken.',
                        autoDismissMilliseconds: 3000
                    }));
                    break;
            }
        })
    }, [dispatch, navigateToNextRecord]);

    /**
     * Stage duplicate record
     */
    const handleStageDuplicateRecord = useCallback(() => {
        dispatch(stageDuplicateRecordAsync(localRecord.current)).unwrap().then((e) => {
            dispatch(addNotification({
                type: NotificationType.INFO,
                text: 'De aanpassingen zijn opgeslagen',
                autoDismissMilliseconds: 3000
            }));
            formIsDirty.current = false;
            setDisableSaveButton(true);
            navigateToNextRecord();
        }).catch((e) => {
            switch (e.status) {
                default:
                    dispatch(addNotification({
                        type: NotificationType.WARNING,
                        text: 'Fout bij het goedkeuren van het record.',
                        autoDismissMilliseconds: 3000
                    }));
                    break;
                case 422:
                    dispatch(addNotification({
                        type: NotificationType.WARNING,
                        text: 'Een duplicaat met dit nummer is al toegevoegd.',
                        autoDismissMilliseconds: 3000
                    }));
                    break;
                case 403:
                    dispatch(addNotification({
                        type: NotificationType.WARNING,
                        text: 'Je bent niet gemachtigd om duplicaten te bewerken.',
                        autoDismissMilliseconds: 3000
                    }));
                    break;
            }
        })
    }, [dispatch, navigateToNextRecord]);

    /**
     * Approve record
     */
    const handleApproveRecord = useCallback((type: SubmitEventType, form: any) => {

        setReasonRejectError(false);

        switch (type) {
            case SubmitEventType.SUCCESS: {
                dispatch(approveRecordAsync(localRecord.current)).unwrap().then((e) => {
                    dispatch(addNotification({
                        type: NotificationType.INFO,
                        text: 'De aanpassingen zijn opgeslagen',
                        autoDismissMilliseconds: 3000
                    }));

                    formIsDirty.current = false;
                    navigateToNextRecord();
                }).catch((e) => {
                    dispatch(addNotification({
                        type: NotificationType.WARNING,
                        text: 'Fout bij het goedkeuren van het record.',
                        autoDismissMilliseconds: 3000
                    }));

                    const response = e?.error as LisRecordValidationErrorResponse;
                    response.errors.forEach(error => {
                        dispatch(addNotification({
                            type: NotificationType.WARNING,
                            text: error.message ?? error.code,
                            autoDismissMilliseconds: 3000
                        }));
                    })
                })
                break;
            }
            case SubmitEventType.ERROR: {

                const errors = Object.values(form);
                const allowedCombinationsFactsError = errors.some((item: any) => item.type === 'allowedCombinationsFacts');
                const uniquePairsLetselError = errors.some((item: any) => item.type === 'uniquePairsLetsel');
                const uniqueValueProductsError = errors.some((item: any) => item.type === 'uniqueValueProducts');

                if (allowedCombinationsFactsError) {
                    dispatch(addNotification({ type: NotificationType.WARNING, text: 'Ongeldige oorzaak letsel combinatie', autoDismissMilliseconds: 3000 }));
                }

                if (uniquePairsLetselError) {
                    dispatch(addNotification({ type: NotificationType.WARNING, text: 'Dubbele letsel/lichaamsdeel waarden.', autoDismissMilliseconds: 3000 }));
                }

                if (uniqueValueProductsError) {
                    dispatch(addNotification({ type: NotificationType.WARNING, text: 'Dubbele product waarden.', autoDismissMilliseconds: 3000 }));
                }

                dispatch(addNotification({
                    type: NotificationType.WARNING,
                    text: 'Vul alle verplichte velden.',
                    autoDismissMilliseconds: 3000
                }));
                break;
            }
        }
    }, [dispatch, navigateToNextRecord]);

    return (
        <>
            <Popup popup={popupObj} />
            <Throbber visible={status === 'loading'} className="throbber--secondary" />
            {record === undefined || status === 'failed' ? (
                <>
                    {status !== 'loading' &&
                        <div className="listview__no-result">
                            <div>Het record kon niet worden geladen.</div>
                        </div>
                    }
                </>
            ) : (
                <div className="move-record">
                    <TitleHeader title={header.title} navigationButton={header.navigationButton} />
                    <div key={record.uniqueKey}>
                        <Form defaultValues={record} onDirtyEvent={handleDirty} onChangeEvent={handleData} onSubmitEvent={handleApproveRecord}>
                            <SectionDefault className="section--compact section--margin-t-m">
                                <SubLayout3Cols className="gutter-l">
                                    <InternalMessage readOnly={record.readOnly} />
                                    <></>
                                </SubLayout3Cols>
                            </SectionDefault>
                            <SectionDefault className="section-compact">
                                <SubLayout3Cols className="gutter-l">
                                    <Record readOnly={record.readOnly} reasonRejectError={reasonRejectError} />
                                    <Person readOnly={record.readOnly} />
                                    <General readOnly={record.readOnly} />
                                </SubLayout3Cols>
                            </SectionDefault>
                            <SectionDefault>
                                <SubLayout3Cols className="gutter-l">
                                    <AccidentFacts readOnly={record.readOnly} />
                                    <Diagnosis readOnly={record.readOnly} />
                                    <Treatment readOnly={record.readOnly} />
                                </SubLayout3Cols>
                            </SectionDefault>
                            <SectionDefault>
                                <SubLayout3Cols className="gutter-l">
                                    <>
                                        <TrafficAccident readOnly={record.readOnly} />
                                        <SportAccident readOnly={record.readOnly} />
                                        <Voilence readOnly={record.readOnly} />
                                        <PrivateAccident readOnly={record.readOnly} />
                                        <WorkAccident readOnly={record.readOnly} />
                                    </>
                                    <Scenario readOnly={record.readOnly} />
                                    <></>
                                </SubLayout3Cols>
                            </SectionDefault>

                            <ActionBar active hasPaging buttonPrevCallback={navigateToPrevRecord} buttonNextCallback={navigateToNextRecord}>
                                {record.lockedByOther ? (
                                    <div className="action-bar__locked">{record.lockedByUsername}</div>
                                ) : (
                                    <>
                                        {record?.[STATUS_FIELD_ID] === STATUS_DUPLICATE ? (
                                            <>
                                                <Button className="button--negative" onClick={(e) => { handleDiscardDuplicateRecord(); e.preventDefault() }}>Verwijderen</Button>
                                                <Button className="button--positive" onClick={(e) => { handleStageDuplicateRecord(); e.preventDefault() }}>Toevoegen</Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button className="button--negative" disabled={record?.[STATUS_FIELD_ID] === STATUS_DECLINED} onClick={(e) => { handleRejectRecord(); e.preventDefault() }}>Afkeuren</Button>
                                                <Button className="button--secondary" disabled={disableSaveButton} onClick={(e) => { handleSaveRecord(); e.preventDefault() }}>Wijzigingen opslaan</Button>
                                                <Button className="button--positive" type="submit" disabled={record?.[STATUS_FIELD_ID] === STATUS_ACCEPTED}>Goedkeuren</Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </ActionBar>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
}

