import { useCallback, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import ComboBox, { ComboBoxItem } from '@support/components/comboBox';
import SearchField from '@support/components/searchField';

import './index.scss';

import {
  setData,
  setRegions,
  setQuery,
} from '@features/organizations/store/slice';

export default function OrganizationsFilter() {

  const {
    filter,
  } = useAppSelector(state => state.organizations)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const dispatch = useAppDispatch();

  const queryTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [localQuery, setLocalQuery] = useState<string>(filter.query);

  // get filter options from cache
  const dataComboBoxItems = useRef<ComboBoxItem[]>(cache.organizationsFilters.data || []);
  const regionsComboBoxItems = useRef<ComboBoxItem[]>(cache.organizationsFilters.regions || []);

  const updateData = useCallback((status: string[]) => {
    dispatch(setData(status))
  }, [dispatch])

  const updateRegions = useCallback((organization: string[]) => {
    dispatch(setRegions(organization))
  }, [dispatch])

  const updateQuery = useCallback((query: string) => {
    setLocalQuery(query);
    if (queryTimeout.current) clearTimeout(queryTimeout.current);
    queryTimeout.current = setTimeout(() => {
      dispatch(setQuery(query))
    }, 500);
  }, [dispatch])

  return (
    <div className="organizations__filter">
      <div className="row">
        <div className="col-md-2">
          <ComboBox items={dataComboBoxItems.current} multiSelect placeholderText="Data" value={filter.data} onChange={updateData} />
        </div>
        <div className="col-md-2">
          <ComboBox items={regionsComboBoxItems.current} multiSelect placeholderText="Regio" value={filter.regions} onChange={updateRegions} />
        </div>
        <div className="col-md-2">
          <SearchField placeholder="Doorzoek de lijst" value={localQuery} onChange={(e) => updateQuery(e.target.value)} />
        </div>
      </div>
    </div>
  );
}
