import { ReactNode } from 'react';
import './SectionDefault.scss';

export type Props = {
  children?: ReactNode,
  className?: string
}

export default function SectionDefault({ children, className = '' }: Props) {
  return (
    <div className={`section section--default ${className}`}>
      {children}
    </div>
  )
}