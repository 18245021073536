import FormRow from '@src/support/components/form/FormRow';
import { Controller, useFormContext } from 'react-hook-form'
import FormFieldset from '@src/support/components/form/FormFieldset';
import TextareaField from '@src/support/components/textareaField';

type Props = {
  readOnly?: boolean,
}

export default function InternalMessage ({ readOnly = false }: Props) {
  const methods = useFormContext();

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Intern bericht</legend>
      <FormRow fieldId="79" isActive={true} classNames={{leftColumn: 'col-12', rightColumn: 'd-none'}}>
        <Controller
          control={methods.control}
          name="79"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextareaField name={name} value={value || ''} error={error} onChange={onChange} maxLength={255} />
            )}
          />
        <></>
      </FormRow>
    </FormFieldset>
  )
}