import { useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { useParams } from 'react-router-dom';

// features
import GeneralDetails from '@features/organizationDetails/components/generalDetails';
import MoveExports from '@features/organizationDetails/components/moveExports';
import LisExports from '@features/organizationDetails/components/lisExports';
import { NotificationType } from '@features/notifications/models';
import { OrganizationDetailsPatch } from '@features/organizationDetails/models/models';
import organizationDetailsAPI from '@features/organizationDetails/api/api';
import { addNotification } from '@features/notifications/slice';

// support
import Form from '@support/components/form';
import SectionDefault from '@layouts/sections/SectionDefault';
import SubLayout3Cols from '@layouts/subLayouts/SubLayout3Cols';
import Throbber from '@support/components/throbber';

// store
import {
    fetchOrganizationDetailsAsync,
    setAutomaticProcessing
} from '@src/features/organizationDetails/store/slice'

import './index.scss'
import { ChangeEvent } from 'react';

type Params = {
    id: string
}

export default function OrganiationDetails() {
    const { id } = useParams() as Params;
    const dispatch = useAppDispatch();

    const {
        organization,
        status,
    } = useAppSelector(state => state.organizationDetails);

    // Load the organization data.
    const loadData = useCallback(() => {
        dispatch(fetchOrganizationDetailsAsync({ id })).unwrap().catch(() => {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Fout bij ophalen van de data.',
                autoDismissMilliseconds: 3000
            }));
        })
    }, [dispatch, id])

    useEffect(() => {
        loadData();
    }, [loadData])

    const handleAutoProcessingChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        const patch: OrganizationDetailsPatch = {
            autoProcessing: e.target.checked
        }

        try {
            const currentState = await organizationDetailsAPI.update(id, patch);
            const autoProcessing = currentState.autoProcessing!;
            
            // Update local copy of organization with updated (current) state.
            dispatch(setAutomaticProcessing(autoProcessing));

            dispatch(addNotification({
                type: NotificationType.INFO,
                text: `Automatische verwerking aangepast: ${autoProcessing ? 'aan' : 'uit'}.`,
                autoDismissMilliseconds: 3000
            }));
        } catch (e) {
            console.error(e);

            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Automatische verwerking kon niet worden aangepast.',
                autoDismissMilliseconds: 3000
            }));
        }
    }, [dispatch, id]);

    const Exports = organization?.data.toLocaleLowerCase() === 'lis' ? LisExports : MoveExports;
    return (
        <>
            <Throbber visible={status === 'loading'} />
            <div className="organization-details">
                {organization ? <>
                    <h1>{organization?.description}</h1>
                    <div>
                        <Form defaultValues={{}} onChangeEvent={() => null} onSubmitEvent={() => null} onDirtyEvent={() => null}>
                            <SectionDefault className="section--compact section--margin-t-m">
                                <SubLayout3Cols className="gutter-1">
                                    <GeneralDetails
                                        organization={organization}
                                        onChangeAutoProcessing={handleAutoProcessingChange}
                                    />
                                    <></>
                                    <></>
                                </SubLayout3Cols>
                            </SectionDefault>
                            <SectionDefault className="section--compact">
                                <SubLayout3Cols className="gutter-1">
                                    <Exports organization={organization} />
                                    <></>
                                    <></>
                                </SubLayout3Cols>                                
                            </SectionDefault>
                        </Form>
                    </div>
                </> : <></>}
            </div>
        </>
    )
}

