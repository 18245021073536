import { ReactNode } from 'react';
import Notifications from '@features/notifications/';

export type Props = {
  header?: ReactNode,
  main?: ReactNode,
  footer?: ReactNode,
}

export default function MasterLayout({ header, main, footer }: Props) {
  return (
    <>
      <Notifications />
      <div>{header}</div>
      <div>
        {main}
      </div>
      <div>{footer}</div>
    </>
  )
}