/*
  * ListViewEventType
  * 
  */
export enum ListViewEventType {
  CHECKBOX_CHECKED = 0,
  CHECKBOX_UNCHECKED,
  CHECK_ALL,
  UNCHECK_ALL,
  SORT_COLUMN,
  DELETE,
  CLICK,
}

/*
  * ListViewEvent
  * 
  */
export interface ListViewEvent {
  event: ListViewEventType,
  itemId?: number | string | undefined,
  payload?: any | undefined,
}

/*
* ListViewItemData
* 
*/
export interface ListViewItemData {
  checked: boolean
  id: string,
  data: any
}

/*
  * ListViewCol
  * 
  */
export interface ListViewCol {
  key: string,
  label: string,
  className?: string,
  sortable?: boolean
}

/*
  * ListViewDefinition
  * 
  */
export interface ListViewDefinition {
  hasCheckBox?: boolean,
  hasLink?: boolean,
  className?: string,
  columns: ListViewCol[],
  onListViewEvent?: (event: ListViewEvent) => void
}

export interface ListViewSort {
  key: string,
  direction: '' | 'desc' | 'asc';
}
