
export enum NotificationType {
  INFO = 0,
  WARNING
}

export interface NotificationObject {
  id?: number | undefined,
  type: NotificationType,
  text: string,
  dismissable?: boolean,
  cssClass?: string | undefined,
  autoDismissMilliseconds?: number,
  dismissActive?: boolean
}