import { ComponentProps, ReactNode } from 'react';
import './index.scss';

interface Props extends ComponentProps<'button'> {
  children?: ReactNode,
  direction: string
}

export default function ButtonDirection({ children, direction, ...rest }: Props) {
  return (
    <button className={`button-direction button-direction--${direction}`} {...rest}>
      {children}
    </button>
  )
}