import { useEffect, useState } from 'react';
import { PopupObject, PopupType } from './models'
import Button from '@src/support/components/button';
import useKeyPresses, { ignoreKeyPress } from '@support/hooks/useKeyPresses';
import './index.scss';

interface Props {
  popup: PopupObject | undefined,
}

function Popup({ popup }: Props) {

  const [cssClass, setCssClass] = useState<string>('popup--info');
  const [visible, setVisible] = useState<boolean>(false);

  /**
   * Handle key presses
   */
  useKeyPresses('Escape', (e) => {
    const el = document.querySelector('.popup .button--negative') as HTMLElement
    if (!ignoreKeyPress()) {
      e.preventDefault();
      el.click();
    }
  });

  useKeyPresses(' ', (e) => {
    const el = document.querySelector('.popup .button--positive') as HTMLElement
    if (el && !ignoreKeyPress()) {
      e.preventDefault();
      el.click();
    }
  }, (e) => {
    if (!ignoreKeyPress()) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    if (popup) {
      switch (popup.type) {
        default:
        case PopupType.INFO:
          setCssClass('popup--info');
          break;
        case PopupType.WARNING:
          setCssClass('popup--warning');
          break;
      }
    }
    setVisible(true);
    setTimeout(() => {
      const el = document.querySelector('.popup .button--positive') as HTMLElement
      if (el) el.focus();
    });

  }, [popup]);

  if (!popup || !visible) return <></>

  return (
    <div className="popup-wrapper">
      <div className={`popup ${cssClass}`}>
        <div className="popup__container">
          <span className="popup__icon"></span>
          <div className="popup__text">
            <h2>{popup.title}</h2>
            <p>{popup.text}</p>
            {popup.buttonConfirm && (
              <Button onClick={() => { if (popup.onConfirm) { popup.onConfirm() } setVisible(false); }} className="button--positive">{popup.buttonConfirm}</Button>
            )}
            {popup.buttonCancel && (
              <Button onClick={() => { if (popup.onCancel) { popup.onCancel(); } setVisible(false); }} className="button--negative">{popup.buttonCancel}</Button>
            )}
          </div>
          {popup.dismissable && (
            <button className="popup__close" onClick={() => { if (popup.onCancel) { popup.onCancel(); } setVisible(false); }}>Close</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Popup;