import { useState, useCallback } from 'react';
import { FormRowVisibility, FormRowEvent, FormRowEventType, FormRowItem } from '../components/form/models';

export function useFormVisibility () {
  const [visibility, setVisibility] = useState<FormRowVisibility>({})

  const updateHandler = useCallback(({ event, payload }: FormRowEvent) => {
    if (event !== FormRowEventType.UPDATE) return;
    
    const item:FormRowItem = payload;

    setVisibility(prevValue => Object.assign({}, prevValue, {
      [item.fieldId]: item.visible
    }))
  }, []);

  const isVisible = Object.values(visibility).includes(true);

  return { visibility, updateHandler, isVisible }
}