import KeyValue from '@src/support/models/keyValue'

export enum SubmitEventType {
  SUCCESS,
  ERROR
}

export enum FormRowEventType {
  UPDATE
}

export interface FormRowEvent {
  event: FormRowEventType,
  payload?: any | null
}

export interface FormRowCssClasses {
  leftColumn?: string,
  rightColumn?: string
}

export interface FormRowItem {
  fieldId: string,
  visible: boolean
}

export interface FormRowVisibility {
  [key: string]: boolean
}

export interface FormData {
  [key: string]: any
}

export interface FormField {
  id: number | null, 
  listId?: number,
  description?: string
  fieldRules?: FormFieldRule[]
}

export interface FormFieldSelect extends FormField {
  options: FormValue[],
  valueRules?: FormValueRule[]
}

export interface FormFieldRule {
  fieldId: number,
  masterFieldId: number,
  masterValueId: number | string | null,
  groupId: number | null,
  groupOperator: string | null
}

export interface FormValue {
  listId: number, 
  id: number,
  label: string,
  synonyms?: string
}

export interface FormValueRule {
  fieldId: number,
  valueId:  number,
  masterFieldId: number,
  masterValueId: number
}

export interface UniquePairsInfo {
  pairs: KeyValue[]
}

export interface UniqueValuesInfo {
  fields: string[]
}

export interface AllowedCombinationsInfo {
  fields: string[],
  values: string[][]
}

export interface FieldValue {
  field: string,
  value: string,
}

export interface ConditionalRequired {
  fieldValue: FieldValue[]
}