import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import organizationsAPI from '@features/organizations/api/api';
import { OrganizationsResponse, OrganizationsItem, OrganizationsFilter } from '@features/organizations/models/models';

// support
import { ListViewSort } from '@support/components/listView/models';

export interface OrganizationsState {
    status: 'idle' | 'loading' | 'failed';
    listData: OrganizationsResponse | undefined,
    itemData: OrganizationsItem | undefined,
    currentPage: number,
    totalPages: number,
    filter: OrganizationsFilter
}

const initialState: OrganizationsState = {
    status: 'idle',
    listData: undefined,
    itemData: undefined,
    currentPage: 1,
    totalPages: 1,
    filter: {
        data: [],
        regions: [],
        query: '',
        page: 1,
        sort: {
            key: '',
            direction: ''
        }
    }
};

/**
 * Fetches the listview with the selected filter settings
 */
export const fetchOrganizationsListAsync = createAsyncThunk<OrganizationsResponse, void, { state: RootState }>(
    'organizations/fetchOrganizationsListAsync',
    async (_, thunkAPI,) => {

        const filter = thunkAPI.getState().organizations.filter;
        const params = new URLSearchParams();
        params.append('page', filter.page.toString())

        if (filter.sort.key !== '') {
            params.append('sortKey', filter.sort.key);
            params.append('sortDirection', filter.sort.direction);
        }

        if (filter.query.length) params.append('query', filter.query);

        filter.data.forEach(s => {
            params.append('data', s);
        });

        filter.regions.forEach(o => {
            params.append('region', o);
        });

        try {
            return await organizationsAPI.list(params);
        } catch (error: any) {
            if (!error.response) throw error
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFilter: (state, action: PayloadAction<OrganizationsFilter>) => {
            state.filter = action.payload;
        },
        /**
        * Filter values
        */
        setData: (state, action: PayloadAction<string[]>) => {
            state.filter.data = action.payload;
            state.filter.page = 1;
        },
        setRegions: (state, action: PayloadAction<string[]>) => {
            state.filter.regions = action.payload;
            state.filter.page = 1;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.filter.page = action.payload;
        },
        setSortKey: (state, action: PayloadAction<ListViewSort>) => {
            state.filter.sort = action.payload;
            state.filter.page = 1;
        },
        setQuery: (state, action: PayloadAction<string>) => {
            if (state.filter.query !== action.payload) {
                state.filter.query = action.payload;
                state.filter.page = 1;
            }
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            // --------------------------------------------------------------------------
            // list
            .addCase(fetchOrganizationsListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationsListAsync.fulfilled, (state, action) => {
                state.listData = action.payload;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.status = 'idle';
            })
            .addCase(fetchOrganizationsListAsync.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setFilter, setData, setRegions, setQuery, setPage, setSortKey } = organizationsSlice.actions;

export default organizationsSlice.reducer;
