import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import lisRecordDataLineageAPI from '@features/lisRecordDataLineage/api/api';
import { LisRecordDataLineageResponse } from '@features/lisRecordDataLineage/models/models';

export interface LisRecordDataLineageState {
    status: 'idle' | 'loading' | 'failed';
    listData: LisRecordDataLineageResponse | undefined,
}

const initialState: LisRecordDataLineageState = {
    status: 'idle',
    listData: undefined,
};

export const fetchLisRecordDataLineageAsync = createAsyncThunk<LisRecordDataLineageResponse, { id: string }, { state: RootState }>(
    'lisRecordDataLineage/fetchLisRecordDataLineageAsync',
    async (payload, thunkAPI,) => {
        const params = new URLSearchParams();

        try {
            return await lisRecordDataLineageAPI.list(payload.id, params);
        } catch (error: any) {
            if (!error.response) throw error
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

export const lisRecordDataLineageSlice = createSlice({
    name: 'lisRecordDataLineage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLisRecordDataLineageAsync.pending, (state) => {
                state.listData = [];
                state.status = 'loading';
            })
            .addCase(fetchLisRecordDataLineageAsync.fulfilled, (state, action) => {
                state.listData = action.payload;
                state.status = 'idle';
            })
            .addCase(fetchLisRecordDataLineageAsync.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

export default lisRecordDataLineageSlice.reducer;
