import { ListViewDefinition } from '@support/components/listView/models';

const listDefinition: ListViewDefinition = {
    hasCheckBox: false,
    hasLink: false,
    className: 'listview--lis listview--norwap listview--sticky-header',
    columns: [{
        label: '',
        key: 'variable',
        sortable: false,
        className: 'listview__col--w25'
    }, {
        label: '100',
        key: 'status100',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w15'
    }, {
        label: '200',
        key: 'status200',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w15'
    }, {
        label: '300',
        key: 'status300',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w15'
    }, {
        label: '400',
        key: 'status400',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w15'
    }, {
        label: 'LIS Data',
        key: 'status500',
        sortable: false,
        className: 'listview__col--ellipsis listview__col--w15'
    }
    ]
};

export default listDefinition;