import { useEffect } from 'react';

export function useBeforeUnload() {

  useEffect(() => {

    // custom message is no longer supported by modern browsers :-(
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return event.returnValue = 'Are you sure you want to exit?';
    };

    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);
}