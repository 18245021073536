// A mock function to mimic making an async request for data
import { AxiosApi } from '@src/index';
import { AxiosResponse } from 'axios';
const responseBody = (response: AxiosResponse): any => response.data
const apiBaseUrl = '/api/lis';

const requests = {
  get: (url: string, params?: URLSearchParams) => AxiosApi.get(url, { params }).then(responseBody),
  post: (url: string, body: object) => AxiosApi.post(url, body).then(responseBody),
  put: (url: string, body?: any) => AxiosApi.put(url, body).then(responseBody),
  delete: (url: string) => AxiosApi.delete(url).then(responseBody),
}

const lisRecordAPI = {
  save: (id: string, record: any) => requests.put(`${apiBaseUrl}/registrations/${id}`, record),
  record: (id: string) => requests.get(`${apiBaseUrl}/registrations/${id}`),
  lock: (id: string) => requests.put(`${apiBaseUrl}/registrations/${id}/lock`),
  unlock: (id: string) => requests.delete(`${apiBaseUrl}/registrations/${id}/lock`),
  discardDuplicate: (id: string) => requests.delete(`${apiBaseUrl}/registrations/error/${id}/discard`),
  stageDuplicate: (id: string) => requests.put(`${apiBaseUrl}/registrations/error/${id}/stage`),
}

export default lisRecordAPI