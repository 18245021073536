import './index.scss';

interface Props {
  visible?: boolean
  className?: string
}

export default function Throbber({ visible = true, className = '' }: Props) {
  return (
    <div className={`throbber-container ${className} ${visible ? 'throbber-container--visible' : 'throbber-container--hidden'}`}>
      <div className="throbber"></div>
    </div>
  )
}
