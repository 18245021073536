import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import moveUploadsAPI from '@features/moveUploads/api/api';
import { MoveUploadsResponse, MoveUploadsFilter } from '@features/moveUploads/models/models';

// support
import { ListViewSort } from '@support/components/listView/models';

export interface MoveUploadsState {
  status: 'idle' | 'loading' | 'failed';
  listData: MoveUploadsResponse | undefined,
  currentPage: number,
  totalPages: number,
  filter: MoveUploadsFilter
}

const initialState: MoveUploadsState = {
  status: 'idle',
  listData: undefined,
  currentPage: 1,
  totalPages: 1,
  filter: {
    statuses: [],
    organizations: [],
    startDate: '',
    endDate: '',
    query: '',
    page: 1,
    sort: {
      key: '',
      direction: ''
    }
  }
};

/**
 * Fetches the listview with the selected filter settings
 */
export const fetchMoveUploadsListAsync = createAsyncThunk<MoveUploadsResponse, void, { state: RootState }>(
  'moveUploads/fetchMoveUploadsListAsync',
  async (_, thunkAPI,) => {

    const filter = thunkAPI.getState().moveUploads.filter;
    const params = new URLSearchParams();
    params.append('page', filter.page.toString())

    if (filter.sort.key !== '') {
      params.append('sortKey', filter.sort.key);
      params.append('sortDirection', filter.sort.direction);
    }

    if (filter.query.length) params.append('query', filter.query);

    filter.statuses.forEach(s => {
      params.append('status', s);
    });

    filter.organizations.forEach(o => {
      params.append('organization', o);
    });

    if (filter.startDate) params.append('startDate', filter.startDate.toString());
    if (filter.endDate) params.append('endDate', filter.endDate.toString());

    try {
      return await moveUploadsAPI.list(params);
    } catch (error: any) {
      if (!error.response) throw error
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const moveUploadsSlice = createSlice({
  name: 'moveUploads',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFilter: (state, action: PayloadAction<MoveUploadsFilter>) => {
      state.filter = action.payload;
    },
    /**
    * Filter values
    */
    setStatus: (state, action: PayloadAction<string[]>) => {
      state.filter.statuses = action.payload;
      state.filter.page = 1;
    },
    setOrganization: (state, action: PayloadAction<string[]>) => {
      state.filter.organizations = action.payload;
      state.filter.page = 1;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.filter.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.filter.endDate = action.payload;
      state.filter.page = 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filter.page = action.payload;
    },
    setSortKey: (state, action: PayloadAction<ListViewSort>) => {
      state.filter.sort = action.payload;
      state.filter.page = 1;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      if (state.filter.query !== action.payload) {
        state.filter.query = action.payload;
        state.filter.page = 1;
      }
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // --------------------------------------------------------------------------
      // list
      .addCase(fetchMoveUploadsListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMoveUploadsListAsync.fulfilled, (state, action) => {
        state.listData = action.payload;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.status = 'idle';
      })
      .addCase(fetchMoveUploadsListAsync.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setFilter, setStatus, setOrganization, setStartDate, setEndDate, setQuery, setPage, setSortKey } = moveUploadsSlice.actions;

export default moveUploadsSlice.reducer;
