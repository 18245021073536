import ButtonDirection from '@support/components/buttonDirection';
import './index.scss';

interface Props {
  currentPage?: number,
  numPages?: number,
  onPageChange?: (page: number) => void,
  textPrevious: string,
  textNext: string
}

export default function Pagination({
  currentPage = 1, numPages = 1, onPageChange, textPrevious, textNext,
}: Props) {
  const handleNextPage = () => {
    if (onPageChange) {
      onPageChange(Math.min(currentPage + 1, numPages));
    }
  };

  const handlePrevPage = () => {
    if (onPageChange) {
      onPageChange(Math.max(currentPage - 1, 1));
    }
  };

  return (
    <>
      {numPages > 1 && (
        <div className="pagination">
          <ButtonDirection disabled={currentPage === 1} direction="left" onClick={handlePrevPage}>{textPrevious}</ButtonDirection>
          <span>
            {currentPage}
            /
            {numPages}
          </span>
          <ButtonDirection disabled={currentPage === numPages} direction="right" onClick={handleNextPage}>{textNext}</ButtonDirection>
        </div>
      )}
    </>
  );
}
