import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function PersonCharacteristics({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler, isVisible } = useFormVisibility();
  
  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_19 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 19, description: 'Airbag', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_18 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 18, description: 'Gordel', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_17 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 17, description: 'Helm', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_64 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 64, description: 'Kinderzitje', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_44 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 44, description: 'ADAS', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  
  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Beschermmiddelen</legend>
      <FormRow fieldId="19" isActive={visibility[19]} fieldRules={field_19.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_19.current.description}</span>
        <Controller
          control={methods.control}
          name="19"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_19.current.options} valueRules={field_19.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="18" isActive={visibility[18]} fieldRules={field_18.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_18.current.description}</span>
        <Controller
          control={methods.control}
          name="18"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_18.current.options} valueRules={field_18.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="17" isActive={visibility[17]} fieldRules={field_17.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_17.current.description}</span>
        <Controller
          control={methods.control}
          name="17"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_17.current.options} valueRules={field_17.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="64" isActive={visibility[64]} fieldRules={field_64.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_64.current.description}</span>
        <Controller
          control={methods.control}
          name="64"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_64.current.options} valueRules={field_64.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="44" isActive={visibility[44]} fieldRules={field_44.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_44.current.description}</span>
        <Controller
          control={methods.control}
          name="44"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_44.current.options} valueRules={field_44.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}