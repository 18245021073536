import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import InputField from '@src/support/components/inputField';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext, FieldError } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormField, getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function ControlCenterText({ readOnly = false }: Props) {
  const methods = useFormContext();  
  const { visibility, updateHandler } = useFormVisibility();

  const {
    record,
  } = useAppSelector(state => state.moveRecord)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_1 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 1, description: 'Type ongeval', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_2 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 2, description: 'Reden exclusie', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_3 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 3, description: 'Type overig ongeval', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_66 = useRef<FormField>(getFormField({ fieldId: 66, description: 'Reden exclusie overig', formFields: formFields.current, fieldRules: formFieldRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Meldkamertekst</legend>
      <FormRow fieldId="75" isActive={true} classNames={{leftColumn: 'col-12', rightColumn: 'd-none'}}>
        <span>{record?.[75]}</span>
        <></>
      </FormRow>
      <FormRow fieldId="1" isActive={true}>
        <span>{field_1.current.description}</span>
        <Controller
          control={methods.control}
          name="1"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_1.current.options} valueRules={field_1.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="2" isActive={visibility[2]} fieldRules={field_2.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_2.current.description}</span>
        <Controller
          control={methods.control}
          name="2"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_2.current.options} valueRules={field_2.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="66" isActive={visibility[66]} fieldRules={field_66.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_66.current.description}</span>
        <InputField name="66" error={methods.formState.errors[66] as FieldError} config={{ required: true }} />
      </FormRow>
      <FormRow fieldId="3" isActive={visibility[3]} fieldRules={field_3.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_3.current.description}</span>
        <Controller
          control={methods.control}
          name="3"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_3.current.options} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}