import { ListViewDefinition } from '@support/components/listView/models';

const listDefinition: ListViewDefinition = {
  hasCheckBox: false,
  hasLink: false,
  className: 'listview--lis listview--norwap listview--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    sortable: true
  }, {
    label: 'Organisatie',
    key: 'organization',
    sortable: true
  }, {
    label: 'Uploaddatum',
    key: 'date',
    sortable: true,
  }, {
    label: 'Bestandsnaam',
    key: 'filename',
    sortable: false,
    className: 'listview__col--ellipsis listview__col--w75'
  }, {
    label: 'Aantal',
    key: 'numberOfRegistrations',
    sortable: true,
    className: 'listview__col--w25'
  }]
};

export default listDefinition;