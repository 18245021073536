import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import moveRecordHistoryAPI from '@features/moveRecordHistory/api/api';
import { MoveRecordHistoryResponse, MoveRecordHistoryItem, MoveRecordHistoryFilter } from '@features/moveRecordHistory/models/models';

// support
import { ListViewSort } from '@support/components/listView/models';

export interface MoveRecordHistoryState {
    status: 'idle' | 'loading' | 'failed';
    listData: MoveRecordHistoryResponse | undefined,
    itemData: MoveRecordHistoryItem | undefined,
    currentPage: number,
    totalPages: number,
    filter: MoveRecordHistoryFilter
}

const initialState: MoveRecordHistoryState = {
    status: 'idle',
    listData: undefined,
    itemData: undefined,
    currentPage: 1,
    totalPages: 1,
    filter: {
        page: 1,
        sort: {
            key: '',
            direction: ''
        }
    }
};

/**
 * Fetches the listview with the selected filter settings
 */
export const fetchMoveRecordHistoryListAsync = createAsyncThunk<MoveRecordHistoryResponse, { id: string }, { state: RootState }>(
    'moveRecordHistory/fetchMoveRecordHistoryListAsync',
    async (payload, thunkAPI,) => {
        const filter = thunkAPI.getState().moveRecordHistory.filter;
        const params = new URLSearchParams();

        params.append('page', filter.page.toString())

        if (filter.sort.key !== '') {
            params.append('sortKey', filter.sort.key);
            params.append('sortDirection', filter.sort.direction);
        }

        try {
            return await moveRecordHistoryAPI.list(payload.id, params);
        } catch (error: any) {
            if (!error.response) throw error
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

export const moveRecordHistorySlice = createSlice({
    name: 'moveRecordHistory',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFilter: (state, action: PayloadAction<MoveRecordHistoryFilter>) => {
            state.filter = action.payload;
        },
        /**
        * Filter values
        */
        setPage: (state, action: PayloadAction<number>) => {
            state.filter.page = action.payload;
        },
        setSortKey: (state, action: PayloadAction<ListViewSort>) => {
            state.filter.sort = action.payload;
            state.filter.page = 1;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            // --------------------------------------------------------------------------
            // list
            .addCase(fetchMoveRecordHistoryListAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMoveRecordHistoryListAsync.fulfilled, (state, action) => {
                state.listData = action.payload;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.status = 'idle';
            })
            .addCase(fetchMoveRecordHistoryListAsync.rejected, (state) => {
                state.status = 'failed';
            })
    },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setFilter, setPage, setSortKey } = moveRecordHistorySlice.actions;

export default moveRecordHistorySlice.reducer;
