import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import './index.scss';

export type Props = {
  error?: FieldError,
  children?: ReactNode,
  className?: string
}

export default function InputWrapper ({ error, children, className = ''}: Props) {
  const classNames = [
    'input-wrapper',
    ...(className ? [ className.trim() ] : []),
    ...(error ? ['error'] : [])
  ];

  return (
    <>
      <div className={classNames.join(' ')}>
        {children}
      </div>
      {error && error.message && (
        <span className="d-none" role="alert">
          {error.message}
        </span>
      )}
    </>
  )
}