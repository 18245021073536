import { useEffect } from 'react';

// https://stackoverflow.com/questions/42036865/react-how-to-navigate-through-list-by-arrow-keys


export const ignoreKeyPress = () => {
  const activeElement = document.activeElement as HTMLElement;
  switch (activeElement.tagName) {
    case 'TEXTAREA':
    case 'INPUT':
    case 'SELECT':
      return true;
    default:
      return false;
  }
}

export default function useKeyPresses(key:string, keyUpAction:(e:KeyboardEvent) => void, keyDownAction?:(e:KeyboardEvent) => void) {
  useEffect(() => {
    const onKeyUp = (e:KeyboardEvent) => {
      if (e.key === key) keyUpAction(e)
    }
    const onKeyDown = (e:KeyboardEvent) => {
      if (e.key === key && keyDownAction) keyDownAction(e)
    }
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keydown', onKeyDown);
    } 
  }, [key, keyUpAction, keyDownAction]);
}