import { useAppDispatch } from '@app/store/hooks';
import { useCallback } from 'react';

// features
import ExportControls from '@features/organizationDetails/components/exportControls';
import organizationDetailsAPI from '@features/organizationDetails/api/api';
import { NotificationType } from '@features/notifications/models';
import { OrganizationDetailsResponse } from '@features/organizationDetails/models/models';
import { addNotification } from '@features/notifications/slice';

// support
import DateHelper from '@support/helpers/dateHelper'
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';


export interface Props {
    organization: OrganizationDetailsResponse
}

export default function MoveExports({ organization }: Props) {
    const dispatch = useAppDispatch();

    const exportInquireFile = useCallback(async (startDate: Date, endDate: Date) => {
        const params = new URLSearchParams();
        params.append('startDate', DateHelper.dateToTimeStamp(startDate));
        params.append('endDate', DateHelper.dateToTimeStamp(endDate));

        try {
            await organizationDetailsAPI.exportInquireFile(organization.id, params);

            const startDateString = startDate.toLocaleDateString(DateHelper.DEFAULT_LOCALE);
            const endDateString = endDate.toLocaleDateString(DateHelper.DEFAULT_LOCALE);

            dispatch(addNotification({
                type: NotificationType.INFO,
                text: `Navraagbestand gexporteerd voor de periode ${startDateString} t/m ${endDateString}.`,
                autoDismissMilliseconds: 5000
            }));
        } catch (e) {
            dispatch(addNotification({
                type: NotificationType.WARNING,
                text: 'Het is niet gelukt om het navraagbestand te exporteren.',
                autoDismissMilliseconds: 5000
            }));
        }
    }, [dispatch, organization])

    return (
        <FormFieldset className={'block mb-4'}>
            <legend className="h2">
                Bestanden
            </legend>
            <FormRow fieldId="inquireFile" isActive={true}>
                <span>Navraagbestand</span>
                <ExportControls onSubmit={exportInquireFile}/>
            </FormRow>
        </FormFieldset>
    );
}
