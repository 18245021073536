import { useAppSelector, useAppDispatch } from '@app/store/hooks';
import { ReactNode, useEffect } from 'react';
import Throbber from '@support/components/throbber';
import {
    initCacheAsync
} from './slice';

import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';
import { useMsal } from '@azure/msal-react';

interface Props {
    children: ReactNode,
}

/**
 * 
 * This component manages the locally cached data.
 * The cache is used to reduce unnecessary API calls for datasets that are used throughout 
 * the application that are not update very often. 
 * This data will be stored in a local 'indexed db' and made available to the application 
 * via the 'cache' store.
 * 
 * The Cache component has the following life-cycle:
 * 1. Intercept the application and check the local cache version against the API.
 * 2. If the version is the same, render the app.
 * 3. If the version is outdated, fetch the new cache, then render the app.
 * 
 */
export default function Cache({ children }: Props) {

    const {
        status,
    } = useAppSelector(state => state.cache)
    const { inProgress } = useMsal();

    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if(inProgress === 'none') {
            dispatch(initCacheAsync()).unwrap().catch((e) => {
                dispatch(addNotification({
                  type: NotificationType.WARNING,
                  text: 'Fout bij ophalen van cache',
                  autoDismissMilliseconds: 3000
                }));
              });
        }
    }, [dispatch, inProgress]);

    return (
        <div>
            <Throbber visible={status === 'loading'} />
            {status === 'idle' &&
                <>{children}</>
            }
        </div>
    );
}