import { AxiosApi } from '@src/index';
import { AxiosResponse } from 'axios';
import { URLSearchParams } from 'url';

// features 
import { OrganizationDetailsPatch } from '@features/organizationDetails/models/models';

const responseBody = (response: AxiosResponse): any => response.data
const apiBaseUrl = '/api';

const requests = {
    get: (url: string, params?: URLSearchParams) => AxiosApi.get(url, { params }).then(responseBody),
    post: (url: string, body: object) => AxiosApi.post(url, body).then(responseBody),
    put: (url: string, body: object) => AxiosApi.put(url, body).then(responseBody),
    delete: (url: string) => AxiosApi.delete(url).then(responseBody),
    patch: (url: string, body: object) => AxiosApi.patch(url, body).then(responseBody),
}

const organizationsDetailAPI = {
    details: (id: string) => requests.get(`${apiBaseUrl}/organizations/${id}`),
    update: (id: string, patch: OrganizationDetailsPatch) => requests.patch(`${apiBaseUrl}/organizations/${id}`, patch),
    exportInquireFile: (id: string, params: URLSearchParams) => requests.get(`${apiBaseUrl}/organizations/${id}/inquirefile`, params),
}

export default organizationsDetailAPI