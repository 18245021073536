import { ListViewDefinition } from '@support/components/listView/models';

const listDefinition: ListViewDefinition = {
  hasCheckBox: false,
  hasLink: true,
  className: 'listview--lis listview--norwap listview--sticky-header',
  columns: [{
    label: 'Data',
    key: 'data',
    sortable: true,
    className: ''
  }, {
    label: 'Regio',
    key: 'region',
    sortable: true
  }, {
    label: 'Code',
    key: 'code',
    sortable: true,
  }, {
    label: 'Organisatie',
    key: 'description',
    sortable: false,
    className: 'listview__col--ellipsis listview__col--w75'
  }]
};

export default listDefinition;