import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationObject } from '@features/notifications/models'

export interface NotificationsState {
  notificationId: number,
  notifications: NotificationObject[]
}

const initialState: NotificationsState = {
  notificationId: 0,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notificationss',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addNotification: (state, action: PayloadAction<NotificationObject>) => {
      state.notifications = [...state.notifications, {...action.payload, id:state.notificationId++}];
    },
    removeNotification: (state, action: PayloadAction<number|undefined>) => {
      state.notifications = state.notifications.filter(item => item.id !== action.payload);
    },
    startDismissAnimation: (state, action: PayloadAction<number|undefined>) => {
      state.notifications = state.notifications.map((item) => {
        return item.id === action.payload ? { ...item, dismissActive: true } : item
      });
    }
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { addNotification, removeNotification, startDismissAnimation } = notificationsSlice.actions;

export default notificationsSlice.reducer;
