
import { useCallback } from 'react';
import { ListViewEventType, ListViewEvent, ListViewCol, ListViewDefinition, ListViewSort } from './models';

export type Props = {
  definition: ListViewDefinition,
  allItemsChecked: boolean,
  onListViewEvent?: (event: ListViewEvent) => void,
  sortKey?: ListViewSort | undefined
}

export default function ListViewHeader({ definition, allItemsChecked, onListViewEvent, sortKey }: Props) {

  /*
   * onCheckboxChange
   * send checkbox change event to parent so the model can be updated
   *
   */
  const onCheckboxChange = useCallback((item: any) => {
    if (onListViewEvent) {
      const Event: ListViewEvent = {
        event: allItemsChecked ? ListViewEventType.UNCHECK_ALL : ListViewEventType.CHECK_ALL
      }
      onListViewEvent(Event);
    }
  }, [onListViewEvent, allItemsChecked]);

  /*
    * onToggleSort
    * send sort event to parent so the model can be updated
    *
    */
  const onToggleSort = useCallback((columnKey: string) => {
    if (onListViewEvent) {

      const newSortKey: ListViewSort = {
        key: columnKey,
        direction: ''
      }

      if (sortKey?.key === newSortKey.key) {
        switch (sortKey.direction) {
          case '':
            newSortKey.direction = 'asc';
            break;
          case 'asc':
            newSortKey.direction = 'desc';
            break;
          case 'desc':
            newSortKey.key = '';
            newSortKey.direction = '';
            break;
        }
      } else {
        newSortKey.direction = 'asc';
      }

      // toggle sort key
      const Event: ListViewEvent = {
        event: ListViewEventType.SORT_COLUMN,
        payload: newSortKey
      }

      onListViewEvent(Event);
    }
  }, [onListViewEvent, sortKey]);

  return (
    <thead>
      <tr>
        {definition.hasCheckBox &&
          <th className="listview__checkbox-col"><input type="checkbox" checked={allItemsChecked} onChange={() => onCheckboxChange({})} /></th>
        }
        {definition.columns.map((item: ListViewCol) => (
          item.sortable
            ? <th key={item.key} className={item.className}><div className={`listview__sort ${sortKey?.key === item.key ? sortKey?.direction : ''}`} onClick={() => onToggleSort(item.key)}>{item.label}</div></th>
            : <th key={item.key} className={item.className}><div>{item.label}</div></th>
        ))}
        {definition.hasLink &&
          <th className="listview__link-col"></th>
        }
      </tr>
    </thead>
  );
}

