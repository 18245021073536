import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function ControlCenterText({ readOnly = false }: Props) {
  const methods = useFormContext(); 
  const { visibility, updateHandler, isVisible } = useFormVisibility();
  
  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.formFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.formFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.formValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.formValueRules || []);

  const field_11 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 11, description: 'Vervoerswijze slachtoffer', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_12 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 12, description: 'Verkeersfunctie slachtoffer', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_13 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 13, description: 'Aard verkeersongeval', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_15 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 15, description: 'Vervoerswijze tegenpartij', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_16 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 16, description: 'Type botsing', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_53 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 53, description: 'Eenzijdig valscenario', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_54 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 54, description: 'Type obstakel val', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_14 = useRef<FormFieldSelect>(getFormSelect({ fieldId: 14, description: 'Type obstakel botsing', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  
  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Ongevalskenmerken</legend>
      <FormRow fieldId="11" isActive={visibility[11]} fieldRules={field_11.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_11.current.description}</span>
        <Controller
          control={methods.control}
          name="11"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_11.current.options} valueRules={field_11.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="12" isActive={visibility[12]} fieldRules={field_12.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_12.current.description}</span>
        <Controller
          control={methods.control}
          name="12"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_12.current.options} valueRules={field_12.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="13" isActive={visibility[13]} fieldRules={field_13.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_13.current.description}</span>
        <Controller
          control={methods.control}
          name="13"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_13.current.options} valueRules={field_13.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="15" isActive={visibility[15]} fieldRules={field_15.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_15.current.description}</span>
        <Controller
          control={methods.control}
          name="15"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_15.current.options} valueRules={field_15.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="16" isActive={visibility[16]} fieldRules={field_16.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_16.current.description}</span>
        <Controller
          control={methods.control}
          name="16"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_16.current.options} valueRules={field_16.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="53" isActive={visibility[53]} fieldRules={field_53.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_53.current.description}</span>
        <Controller
          control={methods.control}
          name="53"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_53.current.options} valueRules={field_53.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="54" isActive={visibility[54]} fieldRules={field_54.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_54.current.description}</span>
        <Controller
          control={methods.control}
          name="54"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_54.current.options} valueRules={field_54.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="14" isActive={visibility[14]} fieldRules={field_14.current.fieldRules} onFormRowEvent={updateHandler}>
      <span>{field_14.current.description}</span>
        <Controller
          control={methods.control}
          name="14"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_14.current.options} valueRules={field_14.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}