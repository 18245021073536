import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import MoveRegistrationAPI from '@features/moveRegistration/api/api';
import { MoveRegistrationResponse, MoveRegistrationFilter } from '@features/moveRegistration/models/models';

// support
import { ListViewSort } from '@support/components/listView/models';

export interface MoveRegistrationState {
  status: 'idle' | 'loading' | 'failed';
  listData: MoveRegistrationResponse | undefined,
  recordIds: string[],
  currentPage: number,
  totalPages: number,
  filter: MoveRegistrationFilter
}

const initialState: MoveRegistrationState = {
  status: 'idle',
  listData: undefined,
  recordIds: [],
  currentPage: 1,
  totalPages: 1,
  filter: {
    status: ['300'],
    organization: [],
    startDate: '',
    endDate: '',
    query: '',
    page: 1,
    sort: {
      key: '',
      direction: ''
    }
  }
};

/**
 * Fetches the listview with the selected filter settings
 */
export const fetchMoveRegistrationListAsync = createAsyncThunk<MoveRegistrationResponse, void, { state: RootState }>(
  'moveRegistration/fetchMoveRegistrationListAsync',
  async (_, thunkAPI,) => {

    const filter = thunkAPI.getState().moveRegistration.filter;
    const params = new URLSearchParams();
    params.append('page', filter.page.toString())

    if (filter.sort.key !== '') {
      params.append('sortKey', filter.sort.key);
      params.append('sortDirection', filter.sort.direction);
    }

    if (filter.query.length) params.append('query', filter.query);

    filter.status.forEach(s => {
      params.append('status', s);
    });

    filter.organization.forEach(o => {
      params.append('organization', o);
    });

    if (filter.startDate) params.append('startDate', filter.startDate.toString());
    if (filter.endDate) params.append('endDate', filter.endDate.toString());

    try {
      return await MoveRegistrationAPI.list(params);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Fetches the record id's based on list view filter settings
 * These ids will be used on the record detail page for navigating
 * to the previous / next record
 */
export const fetchMoveRecordIdsAsync = createAsyncThunk<string[], void, { state: RootState }>(
  'moveRegistration/fetchMoveRecordIdsAsync',
  async (_, thunkAPI,) => {

    // get filter from listview
    const filter = thunkAPI.getState().moveRegistration.filter;
    const params = new URLSearchParams();

    if (filter.sort.key !== '') {
      params.append('sortKey', filter.sort.key);
      params.append('sortDirection', filter.sort.direction);
    }

    if (filter.query.length) params.append('query', filter.query);

    filter.status.forEach(s => {
      params.append('status', s);
    });

    filter.organization.forEach(o => {
      params.append('organization', o);
    });

    if (filter.startDate) params.append('startDate', filter.startDate.toString());
    if (filter.endDate) params.append('endDate', filter.endDate.toString());

    try {
      return await MoveRegistrationAPI.ids(params);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const moveRegistrationSlice = createSlice({
  name: 'moveRegistration',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFilter: (state, action: PayloadAction<MoveRegistrationFilter>) => {
      state.filter = action.payload;
    },
    /**
    * Filter values
    */
    setStatus: (state, action: PayloadAction<string[]>) => {
      state.filter.status = action.payload;
      state.filter.page = 1;
    },
    setOrganization: (state, action: PayloadAction<string[]>) => {
      state.filter.organization = action.payload;
      state.filter.page = 1;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.filter.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.filter.endDate = action.payload;
      state.filter.page = 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filter.page = action.payload;
    },
    setSortKey: (state, action: PayloadAction<ListViewSort>) => {
      state.filter.sort = action.payload;
      state.filter.page = 1;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      if (state.filter.query !== action.payload) {
        state.filter.query = action.payload;
        state.filter.page = 1;
      }
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    removePrevNextRecordId: (state, action: PayloadAction<string>) => {
      state.recordIds = state.recordIds.filter(record => record !== action.payload);
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      // --------------------------------------------------------------------------
      // list
      .addCase(fetchMoveRegistrationListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMoveRegistrationListAsync.fulfilled, (state, action) => {
        state.listData = action.payload;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.status = 'idle';
      })
      .addCase(fetchMoveRegistrationListAsync.rejected, (state, action) => {
        // do not show failed when request was canceled
        if (action?.error?.message !== 'canceled') {
          state.status = 'failed';
        }
      })

      .addCase(fetchMoveRecordIdsAsync.fulfilled, (state, action) => {
        state.recordIds = action.payload
      })
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setFilter, setStatus, setOrganization, setStartDate, setEndDate, setQuery, setPage, setSortKey, removePrevNextRecordId } = moveRegistrationSlice.actions;

export default moveRegistrationSlice.reducer;
