import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '@support/components/navigation'
import User from '@features/authentication/components/user'
import './index.scss'

export type Props = {
    authenticated?: boolean
}

export default function Header({ authenticated }: Props) {

    const navigate = useNavigate();
    const onClick = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <div className="header">
            <div className="header__logo">
                <div onClick={onClick}>Workflow &amp; Controle</div>
            </div>
            {authenticated === true && (
                <>
                    <div className="header__navigation">
                        <Navigation />
                    </div>
                    <div className="header_user">
                        <User />
                    </div>
                </>
            )}
        </div>
    )
}