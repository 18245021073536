import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import './index.scss';

interface Props {
  onDirty: () => void,
  onChange: (data: object) => void,
}

export default function FormStateHandler({onDirty, onChange}: Props) {
  const methods = useFormContext();
  const watcher = useWatch();

  const isDirty = methods.formState.isDirty;
  const getValues = methods.getValues;
  
  useEffect(() => {
    if (!isDirty) return;
    onDirty()
  }, [watcher, isDirty, onDirty])

  useEffect(() => {
    const values = getValues()
    onChange(values)
  }, [watcher, getValues, onChange]);

  return (
    <></>
  );
}

