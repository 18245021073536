import { ReactNode, useEffect, memo, useState } from 'react';
import { NotificationObject, NotificationType } from '@features/notifications/models'
import './index.scss';

interface Props {
  children?: ReactNode,
  notification: NotificationObject,
  onDismiss: (notificationId: number | undefined) => void
}

function Notification({ children, notification, onDismiss }: Props) {

  const [cssClass, setCssClass] = useState<string>('notification--info');

  // initialize auto dismiss if autoDismissMilliseconds is set
  useEffect(() => {
    switch (notification.type) {
      default:
      case NotificationType.INFO:
        setCssClass('notification--info');
        break;
      case NotificationType.WARNING:
        setCssClass('notification--warning');
        break;
    }

    if (notification?.autoDismissMilliseconds && notification.autoDismissMilliseconds > 0) {
      const timer = setTimeout(() => {
        if (onDismiss) onDismiss(notification.id);
      }, notification.autoDismissMilliseconds);
      return () => clearTimeout(timer);
    }
  }, [notification, onDismiss]);

  return (
    <div className={`notification ${cssClass} ${notification.dismissActive === true ? 'notification--dismissed' : ''}`}>
      <div className="notification__container">
        <span className="notification__icon"></span>
        <div className="notification__text">
          {children}
        </div>
        {notification.dismissable && (
          <button className="notification__close" onClick={() => { onDismiss(notification.id) }}>Close</button>
        )}
      </div>
    </div>
  )
}

export default memo(Notification);