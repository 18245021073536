import { useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect, getValueLabel } from '@src/support/helpers/formHelper';

type Props = {
  readOnly?: boolean,
}

export default function Person({ readOnly = false }: Props) {

  const {
    record,
  } = useAppSelector(state => state.lisRecord)

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  const field_5 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 5,
    description: 'Nederlandse ingezetene',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_6 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 6,
    description: 'Urgentiegraad',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_7 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 7,
    description: 'Leeftijd',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_8 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 8,
    description: 'Geslacht',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_9 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 9,
    description: 'Bezwaar',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Persoon</legend>
      <FormRow fieldId="5" isActive={true}>
        <span>{field_5.current.description}</span>
        <span>{getValueLabel(record?.[5], field_5.current)}</span>
      </FormRow>

      <FormRow fieldId="6" isActive={true}>
        <span>{field_6.current.description}</span>
        <span>{getValueLabel(record?.[6], field_6.current)}</span>
    </FormRow>

      <FormRow fieldId="7" isActive={true}>
        <span>{field_7.current.description}</span>
        <span>{record?.[7]}</span>
      </FormRow>

      <FormRow fieldId="8" isActive={true}>
        <span>{field_8.current.description}</span>
        <span>{getValueLabel(record?.[8], field_8.current)}</span>
      </FormRow>

      <FormRow fieldId="9" isActive={true}>
        <span>{field_9.current.description}</span>
        <span>{getValueLabel(record?.[9], field_9.current)}</span>
      </FormRow>
    </FormFieldset>
  )
}