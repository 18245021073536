import { WorkflowDefinition } from '@support/components/workflowView/models';

const workflowDefinitionDrenthe: WorkflowDefinition = {
  className: 'workflow workflow--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Jaar',
    key: 'year',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Kwartaal',
    key: 'quarter',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Maand',
    key: 'month',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Datum',
    key: 'date',
    expandable: true,
    className: 'workflow__col--w10' 
  }, {
    label: 'Z30',
    key: 'z30',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z31',
    key: 'z31',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z60',
    key: 'z60',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Totaal',
    key: 'gr',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Toedracht',
    key: 'cause',
    className: 'workflow__col--ellipsis workflow__col--w25',
  }]
};

export default workflowDefinitionDrenthe;