import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {FormFieldRule, FormRowCssClasses, FormRowEvent, FormRowEventType, FormRowItem} from './models';

type Props = {
  fieldId: string,
  children: ReactNode[],
  isActive: boolean,
  classNames?: FormRowCssClasses,
  fieldRules?: FormFieldRule[],
  onFormRowEvent?: (event: FormRowEvent) => void
}

const HAS_ANY_VALUE = null;

/**
 * FormRow()
 *
 * @param {Props} { children, classNames, isActive, fieldId, fieldRules, onFormRowEvent }
 */

export default function FormRow({ children, classNames, isActive, fieldId, fieldRules, onFormRowEvent }: Props) {
  // React form hook
  const methods = useFormContext();

  // React form hook methods
  const getValues = methods.getValues;
  const watch = methods.watch;
  const resetField = methods.resetField;
  
  // Ref `rules`
  //  Array with all condition rules for current field
  const rules = useRef<FormFieldRule[]>(fieldRules ?? []);

  // State `watchers` 
  //  Array with field ids which needs to be watched for changes
  const [watchers, setWatchers] = useState<number[]>([]);

  // evaluateRuleGroup
  // evaluates a group of rules
  const evaluateRuleGroup = useCallback((ruleGroupId: number) => {
    const values = getValues();
    const groupRules = rules.current.filter(rule => rule.groupId === ruleGroupId);

    const isAndOperator = groupRules[0].groupOperator?.toLowerCase() === 'and'

    for (const rule of groupRules) {
      const result = (values[rule.masterFieldId]?.length && rule.masterValueId === HAS_ANY_VALUE) ||
          (values[rule.masterFieldId] && Number(values[rule.masterFieldId]) === Number(rule.masterValueId));

      if (isAndOperator && !result) return false; //"and" rule groups should or have any false
      if (!isAndOperator && result) return true;  // "or" rule groups are true if any is true
    }

    // if "and" rule group and not any false => return true,
    //  if "or" rule group and not any true => return false
    return isAndOperator;
  }, [rules, getValues])

  // getFieldVisibility
  // Returns the current visibility of the form row field
  const getFieldVisibility = useCallback(() => {
    const values = getValues();
    const evaluatedGroupIds: boolean[] = [];
    const matchingRule = rules.current.find(rule => {

      //rule is part of a group, evaluate group instead of single rule
      if(rule.groupId !== null && rule.groupId !== undefined) {
        //check if rule group is already evaluated:
        if(evaluatedGroupIds[rule.groupId]) {
          return evaluatedGroupIds[rule.groupId];
        }

        //cache rule group result before returning
        evaluatedGroupIds[rule.groupId] = evaluateRuleGroup(rule.groupId);
        return evaluatedGroupIds[rule.groupId];
      }
      return (
        (values[rule.masterFieldId]?.length && rule.masterValueId === HAS_ANY_VALUE) ||
        (values[rule.masterFieldId] && Number(values[rule.masterFieldId]) === Number(rule.masterValueId))
      );
    })

    return {
      fieldId: fieldId,
      visible: !rules.current.length || matchingRule ? true : false
    }
  }, [fieldId, rules, getValues, evaluateRuleGroup])

  /** 
   * updateFormField()
   *  Resets the `react form hook` state of field
   */
  const updateFormField = useCallback((visible:FormRowItem) => {
    
    if (visible.visible) return;

    const fieldId = String(visible.fieldId);
    const options = {
      defaultValue: null // ''
    }

    resetField(fieldId, options);
  }, [resetField])

    /** 
   * updateFormRowItem()
   *  Calls the callback function to update parent with it's visibility state
   */
  const updateFormRowItem = useCallback((visible:FormRowItem) => {
    if (typeof onFormRowEvent !== 'function') return;

    const event:FormRowEvent = {
      event: FormRowEventType.UPDATE,
      payload: visible
    }

    onFormRowEvent(event)
  }, [onFormRowEvent])

  /** 
   * useEffect()
   *  Uses watch from React Form Hook to watch any changes on fields. 
   *  
   * When any field changes it will check if the field (id) exists in the 
   *  `watchers` state.
   * 
   * Update parent visibility by calling the methods
   *  updateFormRowItem()
   *  updateFormField()
   */
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      const masterFieldId = Number(name);

      if (!watchers.includes(masterFieldId)) return;
     
      const visible = getFieldVisibility();
      
      updateFormRowItem(visible)
      updateFormField(visible)
    });
    return () => subscription.unsubscribe();
  }, [watch, getFieldVisibility, updateFormRowItem, updateFormField, watchers]);

  /** 
   * useEffect()
   *  Initial setup of form row component. 
   *  
   * It will set the component intial state:
   *  State `rules` - which fieldRule exists for current field
   *  State `watchers` - which (master) fields needs to be watched for changes
   * 
   * It will update the parent visibility by calling the methods
   *  updateFormRowItem()
   *  updateFormField()
   */
  useEffect(() => {
    const fieldWatchers = rules.current.map(rule => rule.masterFieldId);
    const visible = getFieldVisibility();
    
    setWatchers(Array.from(new Set(fieldWatchers)));

    updateFormRowItem(visible)
    updateFormField(visible)
     
  }, [getFieldVisibility, updateFormRowItem, updateFormField])

  // Return empty template when visible is false...
  if (!isActive) return <></>;

  return (
    <div className="row form-row pb-2 align-items-center">
      <div className={classNames && classNames.leftColumn ? classNames.leftColumn : 'col-5'}>
        {children[0]}
      </div>        
      <div className={classNames && classNames.rightColumn ? classNames.rightColumn : 'col-7'}>
        {children[1]}
      </div>
    </div>
  )
}
