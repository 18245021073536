import { WorkflowDefinition } from '@support/components/workflowView/models';

const workflowDefinitionDrenthe: WorkflowDefinition = {
  className: 'workflow workflow--sticky-header',
  columns: [{
    label: 'Status',
    key: 'status',
    expandable: true,
    className: 'workflow__col--w10',
  }, {
    label: 'Jaar',
    key: 'year',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Kwartaal',
    key: 'quarter',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Maand',
    key: 'month',
    expandable: true,
    className: 'workflow__col--w5',
  }, {
    label: 'Datum',
    key: 'date',
    expandable: true,
    className: 'workflow__col--w10' 
  }, {
    label: 'Z02',
    key: 'z02',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z03',
    key: 'z03',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z12',
    key: 'z12',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z17',
    key: 'z17',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z19',
    key: 'z19',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z20',
    key: 'z20',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Z50',
    key: 'z50',
    showTotal: true,
    clickable: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Totaal',
    key: 'nb',
    showTotal: true,
    className: 'workflow__col--align-r workflow__col--w5'
  }, {
    label: 'Toedracht',
    key: 'cause',
    className: 'workflow__col--ellipsis workflow__col--w25',
  }]
};

export default workflowDefinitionDrenthe;