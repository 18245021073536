import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@app/store/hooks';

// features
import Filter from '@features/organizations/components/filter';
import { NotificationType } from '@features/notifications/models';
import { addNotification, } from '@features/notifications/slice';

// support
import Pagination from '@support/components/pagination';
import ListView from '@support/components/listView';
import ListViewItemDataHelper from '@support/components/listView/listViewDataHelper';
import { ListViewItemData, ListViewEventType, ListViewEvent, ListViewSort } from '@support/components/listView/models';
import Throbber from '@support/components/throbber';

// store
import {
  fetchOrganizationsListAsync,
  setPage,
  setSortKey,
} from '@src/features/organizations/store/slice';

/**
 * Define what columns from the API data to show and 
 * what properties they have
 */
import listDefinition from './listDefinition';

export default function List() {

  const [listViewData, setListViewData] = useState<ListViewItemData[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    listData,
    status,
    currentPage,
    filter,
    totalPages,
  } = useAppSelector(state => state.organizations)

  /**
   * Load the list items
   */
  const loadItems = useCallback(() => {
    dispatch(fetchOrganizationsListAsync()).unwrap().catch((e) => {
      dispatch(addNotification({
        type: NotificationType.WARNING,
        text: 'Fout bij ophalen van de lijst.',
        autoDismissMilliseconds: 3000
      }));
    })
  }, [dispatch]);

  /**
   * Filter has changed, get new items
   */
  useEffect(() => {
    loadItems();
  }, [loadItems, filter])

  /**
   * Transform raw data for use in listview as defined in songListDefinition
   */
  useEffect(() => {
    if (listData?.items) {
      setListViewData(ListViewItemDataHelper.transformData(listData.items, listDefinition));
      window.scrollTo({
        top: 0
      });
    }
  }, [listData])

  /**
   * Handle paging changes
   */
  const handlePageChange = useCallback((page: number) => {
    dispatch(setPage(page));
  }, [dispatch]);

  /**
   * Handle list view events
   */
  const onListViewEvent = useCallback((e: ListViewEvent) => {
    switch (e.event) {
      case ListViewEventType.CLICK:
        if (e.itemId) navigate(`/organizations/${e.itemId}`);
        break;
      case ListViewEventType.SORT_COLUMN:
        if (e.payload !== undefined) {
          dispatch(setSortKey(e.payload as ListViewSort));
        }
        break;
    }
  }, [navigate, dispatch]);

  return (
    <>
      <Filter />
      <Throbber visible={status === 'loading'} />
      {listViewData.length === 0 ? (
        <>
          {status !== 'loading' &&
            <div className="listview__no-result">
              <div>Geen resultaten</div>
            </div>
          }
        </>
      ) : (
        <>
          <ListView sortKey={filter.sort} definition={listDefinition} onListViewEvent={onListViewEvent} data={listViewData} />
          <Pagination textPrevious="Vorige" textNext="Volgende" currentPage={currentPage} numPages={totalPages} onPageChange={handlePageChange} />
        </>
      )}
    </>
  )
}
