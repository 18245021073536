import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
  className: string,
  readOnly?: boolean
}

/** 
 * FormFieldset()
 * 
 * @param children {ReactNode}
 * @param className {string}
 * @param readOnly {boolean}
 */
export default function FormFieldset({ children, className, readOnly = false }: Props) {
  return (
    <fieldset disabled={readOnly === true} className={className}>
      {children}
    </fieldset>
  )
}