
import { useMsal } from '@azure/msal-react';
import './index.scss';

export default function User() {

    const { accounts, inProgress } = useMsal();

    return (
        <div className="user">
            {inProgress === true ? (
                <p>loading...</p>
            ) : (
                <p>{accounts[0]?.name}</p>
            )}
        </div>
    );
}